import { FC, useCallback, useEffect, useState } from 'react'
import {
  FormControlLabel,
  Grid,
  IconButton, Stack, styled, Switch,
  TextField,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Address, GeocoderAddress, Nullable } from 'types'
import { formatAddress, parseGeocoderAddress } from 'utils/address'
import Geocode from 'react-geocode'

const MainTextFieldWrapper = styled(Grid)`
  margin-bottom: 20px;
`

const FloorTextField = styled(TextField)`
  width: 32px;
  margin-left: 42px;
`

const SwitchStack = styled(Stack)`
  margin-left: 24px;
  margin-right: 11px;
`

const SearchResultsList = styled(Grid)`
  cursor: pointer;
`

const SearchResultItem = styled(Grid)`
  padding-block: 11px;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.secondary.main};
`

let timeout: ReturnType<typeof setTimeout> | null = null

export interface AddressInputProps {
  label: string
  address: Nullable<Address>
  onAddressChange: (address: Address) => void
}

export const AddressInput: FC<AddressInputProps> = (
  {
    label,
    address,
    onAddressChange
  }) => {
  const formattedAddress = address !== null ? formatAddress(address) : ''
  const [query, setQuery] = useState(formattedAddress)
  const [searchResults, setSearchResults] = useState<Address[]>([])

  const doSearch = useCallback((queryString: string) => {
    Geocode.fromAddress(queryString).then(({ results }: {results: GeocoderAddress[]}) => {
      const parsedAddresses = results
        .map((geocoderAddress) => parseGeocoderAddress(geocoderAddress))
        .filter(_ => _ !== null) as Address[]
      setSearchResults(parsedAddresses)
    }).catch((e) => {
      setSearchResults([])
      console.log(e, 'goecode error')
    })
  }, [])

  useEffect(() => {
    if (query.length > 2 && query !== formattedAddress) {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => doSearch(query), 1500)
    }
  }, [query, doSearch])

  useEffect(() => () => {
    timeout && clearTimeout(timeout)
  }, [])

  // useEffect(() => {
  //   if (query && query !== formattedAddress) {
  //     doSearch(query)
  //   }
  // }, [query, formattedAddress])

  return (
    <Grid container direction={'column'}>
      <MainTextFieldWrapper item>
        <TextField
          label={label}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                color={'secondary'}
                onClick={() => doSearch(query)}>
                <SearchIcon/>
              </IconButton>
            )
          }}/>
        {
          searchResults.length > 0 &&
          <SearchResultsList container direction={'column'}>
            {searchResults.map((a, index) => {
              const addressString = formatAddress(a)
              return (
                <SearchResultItem
                  item
                  key={addressString + index}
                  onClick={() => {
                    onAddressChange(a)
                    setSearchResults([])
                    setQuery(addressString)
                  }}>
                  <Typography>{addressString}</Typography>
                </SearchResultItem>
              )
            })}
          </SearchResultsList>
        }
      </MainTextFieldWrapper>
      <Grid item>
        <Grid container alignItems={'center'}>
          <Grid item>
            <Typography>Sprat</Typography>
          </Grid>
          <Grid item>
            <FloorTextField
              disabled={!address}
              value={address?.floor ?? ''}
              onChange={(e) => address ? onAddressChange({
                ...address,
                floor: e.target.value
              }) : null}
              fullWidth={false}
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: 'center'
                  }
                }
              }}/>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent={'flex-end'}>
              <FormControlLabel
                control={
                  <SwitchStack direction={'row'} alignItems={'center'}>
                    <Typography>Ne</Typography>
                    <Switch
                      disabled={!address}
                      value={address?.hasElevator ?? false}
                      checked={address?.hasElevator ?? false}
                      onChange={(e, hasElevator) => address ? onAddressChange({
                        ...address,
                        hasElevator
                      }) : null}/>
                    <Typography>Da</Typography>
                  </SwitchStack>
                }
                labelPlacement={'start'}
                label={'Dostupan lift?'}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
