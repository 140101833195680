import { api } from 'services/api/api'
import { Image, Nullable, User, UserProfile } from 'types'

export type UserUpdateArgs = Partial<User> & {
  avatar?: Nullable<Image | File>
}

const createFormData = ({ avatar, ...body }: UserUpdateArgs): FormData => {
  const data = new FormData()
  if (avatar) {
    data.append('avatar', avatar as unknown as Blob)
  }

  if (body.name) {
    data.append('name', body.name)
  }
  if (body.email) {
    data.append('email', body.email)
  }
  if (body.userType) {
    data.append('userType', body.userType)
  }
  if (body.phoneNumber) {
    data.append('phoneNumber', body.phoneNumber)
  }
  if (body.acceptedTermsAndConditions) {
    data.append(
      'acceptedTermsAndConditions',
      body.acceptedTermsAndConditions ? '1' : '0'
    )
  }
  data.append(
    'wantsOffersOnMail',
    body.wantsOffersOnMail ? '1' : '0'
  )

  return data
}

export const usersApi = api.injectEndpoints({
  endpoints: build => ({
    getUser: build.query<User, void>({
      query: () => ({
        url: 'users',
        method: 'GET'
      }),
      providesTags: ['User']
    }),
    updateUser: build.mutation<User, UserUpdateArgs>({
      query: args => ({
        url: 'users/update',
        method: 'POST',
        body: createFormData(args)
      }),
      invalidatesTags: ['User']
    }),
    getUserProfile: build.query<UserProfile, number>({
      query: userId => ({
        url: `users/${userId}`
      })
    })
  })
})

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useGetUserProfileQuery
} = usersApi
