import { FC } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  styled
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PostWithOffers } from 'types'

const CardStyled = styled(Card)`
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.palette.secondary.main}; 
  border-radius: 20px;
`

const UpperRow = styled(Box)`
  display: flex;
`

const LowerRow = styled(Box)`
  display: flex;
  margin-top: 12px;
`

const PostDescription = styled(CardContent)`
  width: 60%;
  padding: 0 8px 0 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: Poppins-Regular;
  color: ${({ theme }) => theme.palette.secondary.main};
`

const OfferNumber = styled(CardContent)`
  width: 60%;
  padding: 0;
  color: ${({ theme }) => theme.palette.grey['600']};
`

const Action = styled(CardActions)`
  width: 40%;
  padding: 0;
`
export interface PostCardProps {
  post: PostWithOffers
}
export const PostCard: FC<PostCardProps> = ({ post }) => {
  const navigate = useNavigate()
  const [postImage] = post.postImages
  const image = postImage?.imageUri ?? '/images/Van.png'
  return (
    <CardStyled elevation={0}>
      <UpperRow>
        <PostDescription>
          {post.transportationServicesDescription}
        </PostDescription>
        <CardMedia
          sx={{
            width: '40%',
            aspectRatio: '3 / 2',
            borderRadius: 5
            // border: `1px solid ${palette.grey['700']}`
          }}
          image={image}
         />
      </UpperRow>
      <LowerRow>
        <OfferNumber>
          Ponude ({post.offers.length})
        </OfferNumber>
        <Action>
          <Button
            color={'secondary'}
            size={'small'}
            onClick={() => navigate(`/post/${post.id}`)}>
            Detalji
          </Button>
        </Action>
      </LowerRow>
    </CardStyled>
  )
}
