import { FC, PropsWithChildren, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  styled,
  Typography
} from '@mui/material'
import { Header } from 'components/Header/Header'
import { useGetUserQuery } from 'services/api/usersApi'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'initFirebase'
import { signOut } from 'firebase/auth'

const WrapperGrid = styled(Grid)`
  background: linear-gradient(to bottom, #FF5B7F, #FC9272);
  width: 100%;
  height: 100%;
  padding-top: 30px;
  overflow: auto;
`

const MainPaper = styled(Paper)`
  flex: 1;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding-top: 32px;
  padding-bottom: 64px;
  margin-top: 24px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-inline: 50px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-inline: 16px;
  }
`

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 32px;
  border-radius: 20px;
`

const CloseButton = styled(Button)`
  margin-top: 16px;
`

export const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const [userAuth] = useAuthState(auth)
  const {data: user} = useGetUserQuery(undefined, { skip:  !userAuth})
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    if (user?.userType === 'carrier') {
      signOut(auth).then(() => {
        setShowModal(true)
      })
    }
  }, [user?.userType])
  return (
    <WrapperGrid container justifyContent={'center'}>
      <Grid container item sm={12} md={10} lg={8} xl={6}>
        <Grid container direction={'column'}>
          <Header/>
          <MainPaper>
            {children}
          </MainPaper>
        </Grid>
      </Grid>
      <Modal
        disableEscapeKeyDown
        open={showModal}>
        <ModalBox>
          <Typography>
            Ovaj broj je u upotrebi od strane prevoznika. <br/>
            Molimo unesite drugi broj telefona
          </Typography>
          <CloseButton onClick={() => setShowModal(false)} color={'secondary'}>Zatvori</CloseButton>
        </ModalBox>
      </Modal>
    </WrapperGrid>
  )
}
