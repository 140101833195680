import { FC } from 'react'
import { SMSLoginForm } from 'forms/LoginForm/SMSLoginForm'
import { useGetUserQuery } from 'services/api/usersApi'

export const PhoneChangeForm: FC<{ onComplete(): void }> = ({ onComplete }) => {
  const { data: user } = useGetUserQuery()
  return (
    <SMSLoginForm onComplete={onComplete} usersPhone={user?.phoneNumber} isUpdate/>
  )
}
