import { FC } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { PhoneHandle } from 'icons/PhoneHandle'

const HandleContainer = styled(Grid)`
  margin-right: 8px;
`

export interface PhoneNumberProps {
  contactName: string
  number: string
}
export const PhoneNumber: FC<PhoneNumberProps> = ({ contactName, number }) => {
  return (
    <Grid container alignItems={'center'}>
      <HandleContainer item>
        <PhoneHandle/>
      </HandleContainer>
      <Grid item xs container direction={'column'}>
        <Grid item><Typography variant={'caption'}>{contactName}</Typography></Grid>
        <Grid item><Typography color={'secondary'}>{number}</Typography></Grid>
      </Grid>
    </Grid>
  )
}
