import { FC, SVGProps } from 'react'

export const BigArrowDown: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57.069" height="55" viewBox="0 0 57.069 55" {...props}>
      <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(53.534 2.5) rotate(90)">
        <path id="Path_1307" data-name="Path 1307" d="M0,0H50" transform="translate(0 25)" fill="none" stroke="rgba(136,144,166,0.5)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
        <path id="Path_1308" data-name="Path 1308" d="M0,0,26.3,25,0,50" transform="translate(23.701 0)" fill="none" stroke="rgba(136,144,166,0.5)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
      </g>
    </svg>
  )
}
