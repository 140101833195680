import { FC } from 'react'
import {
  Grid, styled
} from '@mui/material'
import { AddressInput } from 'components/AddressInput/AddressInput'
import { BigArrowDown } from 'icons/BigArrowDown'
import {
  setAddressFrom,
  setAddressTo,
  usePostSlice
} from 'redux/slices/postSlice'
import { useDispatch, useSelector } from 'react-redux'

const ArrowDivider = styled(Grid)`
  margin-top: 12px;
  margin-bottom: 10px;
`

export const AddressesForm: FC = () => {
  const dispatch = useDispatch()
  const {addressFrom, addressTo} = useSelector(usePostSlice)
  return (
    <Grid container direction={'column'}>
      <Grid item>
        <AddressInput
          address={addressFrom}
          onAddressChange={(address) => {
            dispatch(setAddressFrom(address))
          }}
          label={'Adresa preuzimanja'}/>
      </Grid>
      <ArrowDivider item container justifyContent={'center'}>
        <BigArrowDown/>
      </ArrowDivider>
      <Grid item>
        <AddressInput
          address={addressTo}
          onAddressChange={(address) => {
            dispatch(setAddressTo(address))
          }}
          label={'Željena adresa'}/>
      </Grid>
    </Grid>
  )
}
