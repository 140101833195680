import { FC } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { PostPage } from 'pages/PostPage/PostPage'
import { PostsPage } from 'pages/PostsPage/PostsPage'
import { PostDetailsPage } from 'pages/PostDetailsPage/PostDetailsPage'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'initFirebase'

export const MainRoute: FC = () => {
  const [authUser] = useAuthState(auth)
  return (
    <Routes>
      <Route path={'/'} element={<PostPage/>}/>
      {authUser && <Route path={'/my-posts'} element={<PostsPage/>}/>}
      {authUser && <Route path={'/post/:postId'} element={<PostDetailsPage/>}/>}
      {authUser && <Route path={'/edit-post/:postId'} element={<PostPage/>}/>}
      <Route path="*" element={<Navigate replace to={'/'}/>}/>
    </Routes>
  )
}
