import { FC } from 'react'
import {
  MenuItem,
  Select,
  SelectProps,
  styled,
  Typography
} from '@mui/material'
import { getCountries } from 'utils/countries'

const Code = styled(Typography)`
  width: 50px;
`

export const CountryCode: FC<SelectProps> = (props) => {
  return (
    <Select
      variant={'standard'}
      disableUnderline
      renderValue={(selectedValue) => selectedValue as string}
      {...props}>
      {
        getCountries().map((country) => {
          const cc = '+' + country.callingCode.split(',')[0] ?? ''
          return (
            <MenuItem value={cc} key={cc}>
              <Code>{cc}</Code>
              {country.flag} {country.name}
            </MenuItem>
          )
        })
      }
    </Select>
  )
}
