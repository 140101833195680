import { FC, useState } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import { auth } from 'initFirebase'
import { updatePassword, signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'

export const PasswordChangeForm: FC<{ onComplete(): void }> = ({ onComplete }) => {
  const [userAuth] = useAuthState(auth)

  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [oldPasswordError, setOldPasswordError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordConfirmError, setPasswordConfirmError] = useState('')

  const onSave = async () => {
    if (!userAuth || !userAuth.email) {
      return
    }
    if (password.length < 8) {
      setPasswordError('Lozinka mora imati najmanje 8 karaktera')
      return
    }
    if (password !== passwordConfirm) {
      setPasswordError('Lozinka se ne podudaraju')
      return
    }
    const {user} = await signInWithEmailAndPassword(auth, userAuth.email, oldPassword)
    await updatePassword(user, password)
    onComplete()
  }

  return (
    <Grid container flexDirection={'column'} spacing={2} marginTop={2}>
      <Grid item>
        <TextField
          label={'Stara lozinka'}
          value={oldPassword}
          type={'password'}
          error={!!oldPasswordError}
          helperText={oldPasswordError}
          onChange={(e) => {
            setOldPassword(e.target.value)
          }}/>
      </Grid>
      <Grid item>
        <TextField
          label={'Nova lozinka'}
          value={password}
          type={'password'}
          error={!!passwordError}
          helperText={passwordError}
          onChange={(e) => {
            setPassword(e.target.value)
          }}/>
      </Grid>
      <Grid item>
        <TextField
          label={'Potvrdite novu lozinku'}
          value={passwordConfirm}
          type={'password'}
          error={!!passwordConfirmError}
          helperText={passwordConfirmError}
          onChange={(e) => {
            setPasswordConfirm(e.target.value)
          }}/>
      </Grid>
      <Grid item container marginTop={2}>
        <Button color='secondary' variant={'contained'} onClick={onSave}>Izmeni</Button>
      </Grid>
    </Grid>
  )
}
