import { FC, SVGProps } from 'react'

export const Close: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Icon_ionic-md-close" data-name="Icon ionic-md-close" d="M27.523,9.523l-2-2-8,8-8-8-2,2,8,8-8,8,2,2,8-8,8,8,2-2-8-8Z" transform="translate(-7.523 -7.523)" fill="#8890a6"/>
    </svg>
  )
}

