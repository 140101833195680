import { FC, SVGProps } from 'react'

export const Edit: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.182" height="33.182" viewBox="0 0 33.182 33.182" stroke="#fd7e77"  {...props}>
      <g id="Icon_feather-edit" transform="translate(1.5 1.5)">
        <path id="Path_1301" d="M16.5,6H6A3,3,0,0,0,3,9V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V19.5" transform="translate(-3 -2.818)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
        <path id="Path_1302" d="M27.75,3.75a3.182,3.182,0,0,1,4.5,4.5L18,22.5,12,24l1.5-6Z" transform="translate(-3 -2.818)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
      </g>
    </svg>
  )
}
