import { FC, PropsWithChildren } from 'react'
import { styled, Typography } from '@mui/material'

const PriceTypography = styled(Typography)`
  font-family: Poppins-SemiBold;
`

export const Price: FC<PropsWithChildren> = ({ children }) => {
  return (
    <PriceTypography variant={'body2'}>Cena: {children} RSD</PriceTypography>
  )
}
