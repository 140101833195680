import { FC, PropsWithChildren } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { InfoCircleFull } from 'icons/InfoCircleFull'
import {
  PhoneNumber,
  PhoneNumberProps
} from 'components/PhoneNumber/PhoneNumber'

const Wrapper = styled(Grid)`
  border: 2px solid #FD7E7766;
  border-radius: 20px;
  background-color: #FD7E770D;
  padding: 16px;
`

const Body = styled(Grid)`
  margin-top: 12px;
  margin-bottom: 12px;
`
const Title = styled(Typography)`
  margin-left: 12px;
  color: ${({ theme }) => theme.palette.grey[600]}
`


export interface ImportantInfoProps {
  title: string
  phoneProps?: PhoneNumberProps
}

export const ImportantInfo: FC<PropsWithChildren<ImportantInfoProps>> = (
  {
    title,
    children,
    phoneProps
  }) => {
  return (
    <Wrapper container>
      <Grid item container alignItems={'center'}>
        <InfoCircleFull/><Title variant={'body2'}>{title}</Title>
      </Grid>
      <Body item>
        <Typography variant={'caption'}>
          {children}
        </Typography>
      </Body>
      {
        phoneProps &&
        <Grid item>
          <PhoneNumber {...phoneProps}/>
        </Grid>
      }
    </Wrapper>
  )
}
