import { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  styled,
  Tab,
  Tabs,
  Typography, useMediaQuery, useTheme
} from '@mui/material'
import { ServicePickForm } from 'forms/ServicePickForm/ServicePickForm'
import { AddressesForm } from 'forms/AddressesForm/AddressesForm'
import { PostDetailsForm } from 'forms/PostDetailsForm/PostDetailsForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  markCreateAfterLogin,
  setState,
  usePostSlice
} from 'redux/slices/postSlice'
import { toggleLoginModal } from 'redux/slices/appSlice'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'initFirebase'
import {
  useCreatePostMutation,
  useGetPostQuery,
  useUpdatePostMutation
} from 'services/api/postsApi'
import { InstallAppButton } from 'components/InstallAppButton/InstallAppButton'

const FormContainer = styled(Grid)`
  margin-block: 30px;
`

const ButtonsContainer = styled(Grid)`
  margin-top: 32px;
`

const SubTitle = styled(Typography)`
  margin-bottom: 24px;
  text-align: center;
`

const TabsStyled = styled(Tabs)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: calc(100vw - 32px)
  }
`

type ActivePanel = 'i_need' | 'addresses' | 'details' | 'personal_info'

const a11yProps = (activePanel: ActivePanel) => {
  return {
    id: `simple-tab-${activePanel}`,
    'aria-controls': `simple-tabpanel-${activePanel}`
  }
}

const saveText = (panel: Panels, isLoggedIn: boolean) => {
  switch(panel) {
    case 'personal_info':
      return 'Objavi'
    case 'details':
      return isLoggedIn ? 'Objavi' : 'Sačuvaj i nastavi'
    default:
      return 'Sačuvaj i nastavi'
  }
}

type Panels = 'i_need' | 'addresses' | 'details' | 'personal_info'
export const PostPage: FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { postId } = useParams<{ postId: 'string' }>()
  const [activePanel, setActivePanel] = useState<Panels>('i_need')
  const navigate = useNavigate()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const post = useSelector(usePostSlice)
  const confirmedPanels = useRef<Map<Panels, boolean>>(new Map([
    ['i_need', false], ['addresses', false], ['details', false], ['personal_info', false]
  ]))
  const [createPost] = useCreatePostMutation()
  const [updatePost] = useUpdatePostMutation()
  const {data: postData} = useGetPostQuery(Number(postId), {skip: !postId})

  const [authUser] = useAuthState(auth)
  const isLoggedIn = !!authUser

  useEffect(() => {
    if (postData) {
      dispatch(setState(postData))
    }
  }, [postData, dispatch])

  const isValid = useCallback((panel: Panels = activePanel) => {
    switch(panel ?? activePanel) {
      case 'i_need':
        return post.serviceType !== null
      case 'addresses':
        return post.addressFrom !== null && post.addressTo !== null
      case 'details':
        return post.transportationServicesDescription && post.dimensionsDescription
    }
    return false
  }, [post, activePanel])


  const submitData = useCallback(async () => {
    if (!postId) {
      await createPost(post)
    } else {
      await updatePost(post)
    }
    navigate('/my-posts')
  }, [createPost, navigate, post, postId, updatePost])

  return (
    <>
      <SubTitle variant={'h2'}>Kreiraj novi zahtev za prevoz</SubTitle>
      <Box>
        <TabsStyled
          visibleScrollbar
          variant={isXs ? 'scrollable' : 'fullWidth'}
          value={activePanel} onChange={(_, newActivePanel) => {
          setActivePanel(newActivePanel as ActivePanel)
        }}>
          <Tab
            label={'1. Treba mi'}
            {...a11yProps('i_need')}
            value={'i_need'}/>
          <Tab
            disabled={!isValid('i_need') || !confirmedPanels.current.get('i_need')}
            label={'2. Adrese'}
            {...a11yProps('addresses')}
            value={'addresses'}/>
          <Tab
            disabled={!isValid('addresses') || !confirmedPanels.current.get('addresses')}
            label={'3. Detalji'}
            {...a11yProps('details')}
            value={'details'}/>
        </TabsStyled>
        <FormContainer container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            {
              activePanel === 'i_need' &&
              <ServicePickForm/>
            }
            {
              activePanel === 'addresses' &&
              <AddressesForm/>
            }
            {
              activePanel === 'details' &&
              <PostDetailsForm/>
            }
          </Grid>
          <ButtonsContainer container item sm={12} md={10} lg={8}
                            direction={'column'} alignItems={'center'}>
            <Grid container direction={'column'} spacing={4}>
              <Grid item>
                <Button
                  disabled={!isValid()}
                  color={'secondary'}
                  onClick={async () => {
                    const cp = confirmedPanels.current
                    switch (activePanel) {
                      case 'i_need':
                        cp.set('i_need', true)
                        setActivePanel('addresses')
                        break
                      case 'addresses':
                        cp.set('addresses', true)
                        setActivePanel('details')
                        break
                      case 'details':
                        cp.set('details', true)
                        if (!isLoggedIn) {
                          dispatch(markCreateAfterLogin(true))
                          dispatch(toggleLoginModal())
                        } else {
                          await submitData()
                        }
                        break
                    }
                  }}>
                  {saveText(activePanel, isLoggedIn)}
                </Button>
              </Grid>
              <Grid item>
                <InstallAppButton/>
              </Grid>
            </Grid>
          </ButtonsContainer>
        </FormContainer>
      </Box>
    </>
  )
}
