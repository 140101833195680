import { countries } from 'countries-list'

export interface Country {
  name: string
  code: string
  callingCode: string
  flag: string
}

let countriesArray: Country[] | null = null

export const getCountries = (): Country[] => {
  if (countriesArray === null) {
    countriesArray =  Object.entries(countries).map(([code, country]) => ({
      name: country.name,
      code,
      callingCode: country.phone,
      flag: country.emoji
    }))
  }
  return countriesArray
}
