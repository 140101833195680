import { FC, SVGProps } from 'react'

export const CloseCircled: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.18" height="30.18" viewBox="0 0 30.18 30.18" fill="#fd7e77" {...props}>
      <path id="Icon_metro-cancel" data-name="Icon metro-cancel" d="M17.661,1.928a15.09,15.09,0,1,0,15.09,15.09,15.09,15.09,0,0,0-15.09-15.09Zm0,27.351A12.261,12.261,0,1,1,29.921,17.018,12.261,12.261,0,0,1,17.661,29.279ZM22.376,9.473l-4.716,4.716L12.945,9.473,10.116,12.3l4.716,4.716-4.716,4.716,2.829,2.829,4.716-4.716,4.716,4.716,2.829-2.829L20.49,17.018,25.206,12.3Z" transform="translate(-2.571 -1.928)"/>
    </svg>

  )
}
