import { FC, MouseEvent, useState } from 'react'
import {
  Button,
  Grid, Link, Menu, MenuItem,
  Modal,
  styled,
  Typography
} from '@mui/material'
import { signOut } from 'firebase/auth'
import { Van } from 'icons/Van'
import { auth } from 'initFirebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ModalBox, ModalTitle } from 'components/Modal/Modal'
import { Avatar } from 'components/Avatar/Avatar'
import { useGetUserQuery } from 'services/api/usersApi'
import { LoginForm } from 'forms/LoginForm/LoginForm'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLoginModal, useAppSlice } from 'redux/slices/appSlice'
import { useNavigate } from 'react-router-dom'
import { ProfileForm } from 'forms/ProfileForm/ProfileForm'
import { PasswordChangeForm } from 'forms/PasswordChangeForm/PasswordChangeForm'
import { PhoneChangeForm } from 'forms/PhoneChangeForm/PhoneChangeForm'

const HeaderWrapper = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-inline: 8px;
  }
`

const AvatarName = styled(Typography)`
  margin-right: 16px;
`

const VanWrapper = styled(Grid)`
  cursor: pointer;
`

export const Header: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userAuth] = useAuthState(auth)
  const {
    data: user
  } = useGetUserQuery(undefined, { skip: !userAuth })
  const { showLoginModal } = useSelector(useAppSlice)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false)
  const [showPhoneChangeForm, setShowPhoneChangeForm] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <HeaderWrapper container spacing={2} alignItems={'center'}>
      <VanWrapper item onClick={() => navigate('/')}>
        <Van/>
      </VanWrapper>
      <Grid item xs container direction={'column'}>
        <Grid item>
          <Typography variant={'h1'}>Kombi Nacija Aplikacija</Typography>
          <Typography variant={'subtitle1'}>Lako i povoljno do kombi
            prevoza</Typography>
        </Grid>
      </Grid>
      <Grid item>
        {
          userAuth && user &&
            <div>
              <Button
                variant={'text'}
                aria-controls={open ? 'logged-in-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <Grid item>
                  <AvatarName variant={'subtitle1'}>{user.name}</AvatarName>
                </Grid>
                <Grid item>
                  <Avatar src={user.avatarUri}/>
                </Grid>
              </Button>
              <Menu
                id="logged-in-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem onClick={() => {
                  navigate('/my-posts')
                  handleClose()
                }}>
                  Moje objave
                </MenuItem>
                <MenuItem onClick={() => {
                  setShowProfileModal(true)
                  handleClose()
                }}>Profil</MenuItem>
                <MenuItem onClick={() => {
                  setShowPhoneChangeForm(true)
                  handleClose()
                }}>
                  Promena broja telefona
                </MenuItem>
                <MenuItem onClick={() => {
                  setShowPasswordChangeForm(true)
                  handleClose()
                }}>
                  Promena šifre
                </MenuItem>
                <MenuItem onClick={async () => {
                  await signOut(auth)
                  handleClose()
                }}>
                  Izloguj me
                </MenuItem>
              </Menu>
            </div>
        }
        {
          !userAuth &&
          <Button
            color={'primary'}
            onClick={() => dispatch(toggleLoginModal())}
            variant={'text'}>
            <AvatarName variant={'subtitle1'}>Prijavi se</AvatarName>
          </Button>
        }
      </Grid>
      <Modal
        disableEscapeKeyDown
        open={showLoginModal}>
        <ModalBox>
          <ModalTitle
            title={''}
            onClose={() => dispatch(toggleLoginModal())}/>
          <LoginForm/>
        </ModalBox>
      </Modal>
      <Modal
        disableEscapeKeyDown
        open={showProfileModal}>
        <ModalBox>
          <ModalTitle
            title={'Profil'}
            onClose={() => setShowProfileModal(false)}/>
          <ProfileForm onComplete={() => setShowProfileModal(false)}/>
        </ModalBox>
      </Modal>
      <Modal
        disableEscapeKeyDown
        open={showPasswordChangeForm}>
        <ModalBox>
          <ModalTitle
            title={'Promena lozinke'}
            onClose={() => setShowPasswordChangeForm(false)}/>
          <PasswordChangeForm onComplete={() => setShowPasswordChangeForm(false)}/>
        </ModalBox>
      </Modal>
      <Modal
        disableEscapeKeyDown
        open={showPhoneChangeForm}>
        <ModalBox>
          <ModalTitle
            title={'Promeni broj telefona'}
            onClose={() => setShowPhoneChangeForm(false)}/>
          <PhoneChangeForm onComplete={() => setShowPhoneChangeForm(false)}/>
        </ModalBox>
      </Modal>
    </HeaderWrapper>
  )
}
