import { FC } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { PostStatus } from 'types'

const ProgressWrapper = styled(Grid)`
  position: relative;
  align-items: center;
  text-align: center;
  margin-block: 32px;
`

const Line = styled(Grid)`
  position: absolute;
  z-index: 1;
  width: 98%;
  border: 3px solid ${({ theme }) => theme.palette.secondary.main};
`
const Ring = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 5px solid ${({ theme }) => theme.palette.secondary.main};
  background-color: ${({ theme }) => theme.palette.secondary.contrastText};
  z-index: 2;
  display: flex;
  justify-content: center;
  position: relative;
`
const Circle = styled(Ring)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-family: Poppins-Bold;
  }
`

const CheckpointText = styled(Typography)`
  white-space: nowrap;
  position: absolute;
  font-family: Poppins-Regular;
  color: ${({ theme }) => theme.palette.grey['600']};
`
const CheckpointTop = styled(CheckpointText)`
  top: -32px
`
const CheckpointBottom = styled(CheckpointText)`
  bottom: -32px
`

export interface PostProgressProps {
  postStatus: PostStatus
}

export const PostProgress: FC<PostProgressProps> = ({ postStatus }) => {
  const CP1 = postStatus >= PostStatus.Offers ? Circle : Ring
  const CP2 = postStatus >= PostStatus.SelectedCarrier ? Circle : Ring
  const CP3 = postStatus >= PostStatus.OnTheWay ? Circle : Ring
  const CP4 = postStatus >= PostStatus.Finished ? Circle : Ring
  return (
    <ProgressWrapper container justifyContent={'space-between'}>
      <Line container/>
      <Grid item container justifyContent={'space-between'}>
        <CP1>
          <CheckpointBottom>Ponude</CheckpointBottom>
        </CP1>
        <CP2>
          <CheckpointTop>Izabran prevoznik</CheckpointTop>
        </CP2>
        <CP3>
          <CheckpointTop>Prevoz</CheckpointTop>
        </CP3>
        <CP4>
          <CheckpointBottom>Završeno</CheckpointBottom>
        </CP4>
      </Grid>
    </ProgressWrapper>
  )
}
