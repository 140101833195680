import { FC } from 'react'
import { Grid, styled, Avatar as RNAvatar, Typography } from '@mui/material'
import { Rating } from 'components/Rating/Rating'


const AvatarPicture = styled(RNAvatar)`
  width: 40px;
  height: 40px;
  //border: 1px solid ${({ theme }) => theme.palette.grey['700']}
`
export interface AvatarProps {
  src: string
  name?: string
  rate?: number
  date?: string
  onRate?(r: number): void
}
export const Avatar: FC<AvatarProps> = ({ src, name, rate = 0, date, onRate }) => {
  return (
    <Grid container alignItems={'end'} spacing={1}>
      <Grid item>
        <AvatarPicture src={src}/>
      </Grid>
      {
        name !== undefined &&
        <Grid item xs>
          <Grid container>
            <Grid item>
              <Typography variant={'body2'}>{name}</Typography>
              <Rating rating={rate} onRate={onRate}/>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        date !== undefined &&
        <Grid item>
          <Typography variant={'caption'}>{date}</Typography>
        </Grid>
      }
    </Grid>
  )
}
