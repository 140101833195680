import { FC } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { Pin } from 'icons/Pin'
import { Address, Nullable } from 'types'
import { formatAddress } from 'utils/address'


const AddressText = styled(Typography)`
  font-family: Poppins-Medium;
`

export interface FromToProps {
  fromAddress: Nullable<Address>
  toAddress: Nullable<Address>
  wantsCarriers: boolean
}

export const FromTo: FC<FromToProps> = ({ fromAddress, toAddress, wantsCarriers }) => {
  return (
    <Grid container spacing={2}>
      {
        fromAddress &&
        <Grid item container alignItems={'center'} spacing={1}>
          <Grid item>
            <Pin/>
          </Grid>
          <Grid container item xs direction={'column'}>
            <AddressText variant={'caption'}>Od: {formatAddress(fromAddress)}</AddressText>
            <AddressText variant={'caption'}>
              {wantsCarriers ? 'Potrebno iznošenje' : ''}
              {fromAddress.floor ? `${fromAddress.floor}. sprat` : ''}
              {fromAddress.floor && fromAddress.hasElevator ? ', sa liftorm' : ''}
            </AddressText>
          </Grid>
        </Grid>
      }
      {
        toAddress &&
        <Grid item container alignItems={'center'} spacing={1}>
          <Grid item>
            <Pin/>
          </Grid>
          <Grid container item xs direction={'column'}>
            <AddressText variant={'caption'}>Od: {formatAddress(toAddress)}</AddressText>
            <AddressText variant={'caption'}>
              {wantsCarriers ? 'Potrebno iznošenje' : ''}
              {toAddress.floor ? `${toAddress.floor}. sprat` : ''}
              {toAddress.floor && toAddress.hasElevator ? ', sa liftorm' : ''}
            </AddressText>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}
