import { api } from 'services/api/api'
import { UserRatingWithUser } from 'types'

export const userRatingsApi = api.injectEndpoints({
  endpoints: build => ({
    getPendingUserRatings: build.query<UserRatingWithUser[], void>({
      query: () => ({
        url: 'user-ratings/pending'
      }),
      providesTags: ['PendingRatings']
    }),
    addUserRating: build.mutation<
      void,
      {
        postId: number
        userId: number
        rate: number
        comment: string
      }
    >({
      query: params => ({
        url: 'user-ratings/add',
        body: params,
        method: 'POST'
      })
    })
  })
})

export const { useGetPendingUserRatingsQuery, useAddUserRatingMutation } =
  userRatingsApi
