import { FC } from 'react'
import { Grid, Modal, styled, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setUserRatingId, useAppSlice } from 'redux/slices/appSlice'
import { ModalBox, ModalTitle } from 'components/Modal/Modal'
import { Avatar } from 'components/Avatar/Avatar'
import { useGetUserProfileQuery } from 'services/api/usersApi'
import { getLocalDate } from 'utils/dates'
import { hidePhoneNumber } from 'utils/helpers'

const ServiceDesc = styled(Grid)`
  max-width: 500px;
`

const UserRatingRow = styled(Grid)`
  border-bottom: 1px solid #8890A6;
`

export const UserRatingsWidget: FC = () => {
  const dispatch = useDispatch()
  const { userRatingId } = useSelector(useAppSlice)
  const closeModal = () => dispatch(setUserRatingId(null))

  const { data: userProfile } = useGetUserProfileQuery(userRatingId!, { skip: userRatingId === null })

  if (!userProfile) {
    return null
  }

  const { carrier, name, avatarUri, userRatings = [], posts } = userProfile

  const rate = !userRatings.length ? 0 :
    Number((userRatings.reduce((sum, { rate }) => sum + rate, 0) / userRatings.length).toFixed(1))

  return (
    <Modal
      style={{ overflow: 'scroll' }}
      disableEscapeKeyDown
      open={userRatingId !== null}>
      <ModalBox>
        <ModalTitle
          title={''}
          onClose={closeModal}/>
        <Grid container item>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Avatar
                src={avatarUri}
                name={name}
                rate={rate ?? 0}/>
            </Grid>
          </Grid>
          <ServiceDesc item>
            <Typography
              variant={'caption'}>
              {hidePhoneNumber(carrier.servicesDescription ?? '')}</Typography>
          </ServiceDesc>
          <Grid item container marginTop={2}>
            <Grid xs={6} item container flexDirection={'column'}
                  alignItems={'center'}>
              <Grid item>
                <Typography
                  variant={'caption'}>
                  Broj voznji
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={'secondary'} variant={'h6'}>{posts.length}</Typography>
              </Grid>
            </Grid>
            <Grid xs={6} item container flexDirection={'column'}
                  alignItems={'center'}>
              <Grid item>

                <Typography
                  variant={'caption'}>
                  Ocena
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={'secondary'} variant={'h6'}>{rate}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container flexDirection={'column'}>
            {
              userRatings.map((ur) => (
                <UserRatingRow item container key={ur.id} marginBottom={2}>
                  <Avatar
                    src={ur.raterAvatarUri}
                    rate={ur.rate}
                    name={ur.raterName}
                    date={getLocalDate(new Date(ur.createdAt))}/>
                  <Grid item marginTop={1} paddingBottom={2}>
                    <Typography variant={'caption'}>{ur.comment}</Typography>
                  </Grid>
                </UserRatingRow>
              ))
            }
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  )
}
