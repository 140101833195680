import { api } from 'services/api/api'
import {
  Address,
  Image,
  Nullable,
  Offer,
  OfferWithCarrier,
  Post,
  PostFull,
  PostWithOffers
} from 'types'

const appendAddressData = (
  formData: FormData,
  address: Address,
  addressKey: string
): FormData => {
  for (let key in address) {
    const k = key as keyof Address
    formData.append(
      `${addressKey}[${key}]`,
      k === 'hasElevator' ? (address[k] ? '1' : '0') : `${address[k] ?? ''}`
    )
  }
  return formData
}

/**
 * @TODO I do not like it. Find better way to upload images and save post data. Maybe two requests?
 * @param {Image[]} images
 * @param {Omit<Omit<Post, "postImages"> & {images: Image[]}, "images">} post
 * @returns {FormData}
 */
const createFormData = ({
  images,
  ...post
}: Post & { images: Image[] }): FormData => {
  let formData = new FormData()
  images.forEach(img => {
    formData.append('images[]', img as unknown as Blob)
  })
  if (post.id) {
    formData.append('id', `${post.id}`)
  }
  formData.append('wantsCarriers', post.wantsCarriers ? '1' : '0')

  if (post.addressFrom) {
    formData = appendAddressData(formData, post.addressFrom, 'addressFrom')
  }
  if (post.addressTo) {
    formData = appendAddressData(formData, post.addressTo, 'addressTo')
  }
  if (post.transportDate) {
    formData.append('transportDate', post.transportDate)
  }
  if (post.dimensionsDescription) {
    formData.append('dimensionsDescription', post.dimensionsDescription)
  }
  if (post.transportationServicesDescription) {
    formData.append(
      'transportationServicesDescription',
      post.transportationServicesDescription
    )
  }
  if (post.distance) {
    formData.append('distance', `${post.distance}`)
  }
  if (post.serviceType) {
    formData.append('serviceType', `${post.serviceType}`)
  }
  if (post.postImages.length > 0) {
    formData.append('postImagesJson', JSON.stringify(post.postImages))
  }

  return formData
}

export const postsApi = api.injectEndpoints({
  endpoints: build => ({
    createPost: build.mutation<Post, Omit<Post, 'id'> & { images: Image[] }>({
      query: post => ({
        url: '/posts',
        method: 'PUT',
        body: createFormData(post)
      }),
      invalidatesTags: ['Posts']
    }),
    updatePost: build.mutation<Post, Post & { images: Image[] }>({
      query: post => ({
        url: '/posts',
        method: 'POST',
        body: createFormData(post)
      }),
      invalidatesTags: ['Posts', 'Post']
    }),
    getPost: build.query<PostFull, Post['id']>({
      query: postId => ({
        url: `/posts/${postId}`,
        method: 'GET'
      }),
      providesTags: ['Post']
    }),
    getPosts: build.query<PostWithOffers[], void>({
      query: () => ({
        url: '/posts',
        method: 'GET'
      }),
      providesTags: ['Posts']
    }),
    searchPosts: build.query<Post[], void>({
      query: () => ({
        url: '/posts/search',
        method: 'GET'
      }),
      providesTags: ['Posts']
    }),
    getOffer: build.query<Nullable<Offer>, Offer['id']>({
      query: offerId => ({
        url: `/posts/offer/${offerId}`
      }),
      providesTags: ['Offer']
    }),
    sendOffer: build.mutation<void, Offer>({
      query: offer => ({
        url: '/posts/offer',
        method: 'POST',
        body: offer
      }),
      invalidatesTags: (_, __, arg) =>
        arg.id ? ['Offers', 'Posts', 'Offer'] : ['Offers', 'Posts']
    }),
    getOffers: build.query<OfferWithCarrier[], number>({
      query: postId => ({
        url: `/posts/${postId}/offers`
      }),
      providesTags: ['Offers']
    }),
    acceptOffer: build.mutation<void, { postId: number; offerId: number }>({
      query: ({ postId, offerId }) => ({
        url: `/posts/${postId}/accept-offer/${offerId}`,
        method: 'POST'
      }),
      invalidatesTags: ['Offers', 'Post', 'Posts']
    }),
    cancelPost: build.mutation<void, number>({
      query: postId => ({
        url: `/posts/${postId}/cancel`,
        method: 'POST'
      }),
      invalidatesTags: ['Posts']
    }),
    cancelAcceptedOffer: build.mutation<void, number>({
      query: postId => ({
        url: `/posts/${postId}/cancel-accepted-offer`,
        method: 'POST'
      }),
      invalidatesTags: ['Post', 'Offers']
    })
  })
})

export const {
  useCreatePostMutation,
  useUpdatePostMutation,
  useGetPostQuery,
  useGetPostsQuery,
  useSearchPostsQuery,
  useGetOfferQuery,
  useSendOfferMutation,
  useGetOffersQuery,
  useAcceptOfferMutation,
  useCancelPostMutation,
  useCancelAcceptedOfferMutation
} = postsApi
