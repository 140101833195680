import { FC } from 'react'
import {
  FormControlLabel,
  Grid, Stack,
  styled, Switch, TextField,
  Typography
} from '@mui/material'
import {
  setDimensionsDescription,
  setTransportationServicesDescription, setTransportDate, toggleWantsCarriers,
  usePostSlice
} from 'redux/slices/postSlice'
import { useDispatch, useSelector } from 'react-redux'

const Label = styled(Typography)`
  margin-top: 21px;
  margin-bottom: 15px;
`

const FCLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  margin-top: 25px;
`

export const PostDetailsForm: FC = () => {
  const dispatch = useDispatch()
  const post = useSelector(usePostSlice)
  return (
    <Grid container direction={'column'}>
      <Grid item container direction={'column'}>
      </Grid>
      <Grid item container direction={'column'}>
        <Grid item marginBottom={2}>
          <Label variant={'body2'}>Unesite datum prevoza</Label>
          <TextField
            type={'date'}
            value={post.transportDate}
            onChange={(e) => {
              dispatch(setTransportDate(e.target.value))
            }}/>
        </Grid>
        <Grid item>
          <Label variant={'body2'}>Opis potrebne usluge prevoza</Label>
          <TextField
            value={post?.transportationServicesDescription ?? ''}
            onChange={(e) => {
              dispatch(setTransportationServicesDescription(e.target.value))
            }}
            multiline
            rows={4}
            variant={'outlined'}
            placeholder={'Naziv, Količina, Težina\nŽeljeni rok ili vreme transporta'}/>
        </Grid>
        <Grid item>
          <Label variant={'body2'}>Dimenzije stvari</Label>
          <TextField
            value={post?.dimensionsDescription ?? ''}
            onChange={(e) => {
              dispatch(setDimensionsDescription(e.target.value))
            }}
            multiline
            rows={4}
            variant={'outlined'}
            placeholder={'Unesite dimenzije robe radi bolje procene i tačnije ponude prevoznika'}/>
        </Grid>
        <Grid item>
          <FCLabel
            control={
              <Stack direction={'row'} alignItems={'center'}>
                <Typography>Ne</Typography>
                <Switch
                  value={post?.wantsCarriers}
                  onChange={() => {
                    dispatch(toggleWantsCarriers())
                  }}/>
                <Typography>Da</Typography>
              </Stack>
            }
            labelPlacement={'start'}
            label={'Potrebni su radnici za iznošenje?'}/>
        </Grid>
      </Grid>
    </Grid>
  )
}
