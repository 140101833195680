import { FC, useState } from 'react'
import {
  Button,
  FormControlLabel,
  Grid, Modal,
  Stack,
  styled,
  Switch,
  Typography
} from '@mui/material'
import { PostProgress } from 'components/PostProgress/PostProgress'
import { FromTo } from 'components/FromTo/FromTo'
import { OfferItem } from 'components/OfferItem/OfferItem'
import { InstallAppButton } from 'components/InstallAppButton/InstallAppButton'
import { Edit } from 'icons/Edit'
import { ButtonIconTop } from 'components/ButtonIconTop/ButtonIconTop'
import { CloseCircled } from 'icons/CloseCircled'
import {
  useCancelAcceptedOfferMutation,
  useCancelPostMutation,
  useGetOffersQuery,
  useGetPostQuery
} from 'services/api/postsApi'
import { useNavigate, useParams } from 'react-router-dom'
import { OfferStatus, PostStatus } from 'types'
import { ImportantInfo } from 'components/ImportantInfo/ImportantInfo'
import { ModalActions, ModalBox, ModalTitle } from 'components/Modal/Modal'
import { getLocalDate } from 'utils/dates'

const PostDetailsWrapper = styled(Grid)`
`

const FCLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
`

const DashedGrid = styled(Grid)`
  border-bottom: 1px dashed ${({ theme }) => `${theme.palette.grey['600']}80`};
  padding-block: 20px;
`

export const PostDetailsPage: FC = () => {
  const { postId } = useParams<{ postId: 'string' }>()
  const { data: post } = useGetPostQuery(Number(postId))
  const { data: offers = [] } = useGetOffersQuery(Number(postId))
  const [showCancelPostModal, setShowCancelPostModal] = useState(false)
  const navigate = useNavigate()
  const [cancelPost] = useCancelPostMutation()
  const [cancelAcceptedOffer] = useCancelAcceptedOfferMutation()
  const hasAcceptedOffer = offers.some(_ => _.status === OfferStatus.ACCEPTED)
  const [showLegalSubjects, setShowLegalSubjects] = useState(false)

  if (!post) {
    return null // @todo loader
  }
  const showOnlyAcceptedOffers = post.status === PostStatus.SelectedCarrier
  const [postImage] = post.postImages
  const acceptedOffer = offers.find(_ => _.status === OfferStatus.ACCEPTED)

  return (
    <PostDetailsWrapper container justifyContent={'center'}>
      <Grid item container direction={'column'} xs={12} md={8} spacing={4}>
        <Grid item container justifyContent={'center'}>
          <Grid item xs={11} sm={12}>
            <PostProgress postStatus={post.status}/>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} marginBottom={2}>
            Datum transporta: <b>{getLocalDate(new Date(post.transportDate))}</b>
          </Typography>
          <br/>
          <br/>
          <Typography variant={'caption'}>
            {post.transportationServicesDescription}
          </Typography>
          <br/>
          <Typography variant={'caption'}>
            {post.dimensionsDescription}
          </Typography>
        </Grid>
        {
          postImage &&
          <Grid item container justifyContent={'center'}>
            <img
              style={{ borderRadius: 25 }}
              width={'80%'}
              alt={'alt'}
              src={postImage.imageUri}/>
          </Grid>
        }
        <Grid item>
          <FromTo
            fromAddress={post.addressFrom}
            toAddress={post.addressTo}
            wantsCarriers={post.wantsCarriers}/>
        </Grid>
        <Grid item container direction={'column'} alignItems={'center'}>
          {
            !showOnlyAcceptedOffers &&
            <>
              <Grid item>
                <Typography variant={'h4'}>
                  Ponude za prevoz
                </Typography>
              </Grid>
              <DashedGrid item container>
                <FCLabel
                  control={
                    <Stack direction={'row'} alignItems={'center'}>
                      <Typography>Ne</Typography>
                      <Switch/>
                      <Typography>Da</Typography>
                    </Stack>
                  }
                  value={showLegalSubjects}
                  onChange={() => setShowLegalSubjects(!showLegalSubjects)}
                  labelPlacement={'start'}
                  label={'Prikaži samo pravna lica?'}/>
              </DashedGrid>
            </>
          }
          {
            offers
            .filter(_ => !showOnlyAcceptedOffers || _.status === OfferStatus.ACCEPTED)
            .filter(({ carrier }) => !showLegalSubjects || carrier.isLegalSubject)
            .map((offer) => (
              <DashedGrid item container key={offer.id}>
                <OfferItem offer={offer}/>
              </DashedGrid>
            ))
          }
        </Grid>
        {
          showOnlyAcceptedOffers && acceptedOffer !== undefined &&
          <Grid item>
            <ImportantInfo
              title={'VAŽNO JE DA ZNATE'}
              phoneProps={{
                number: acceptedOffer.carrier.user.phoneNumber,
                contactName: 'Kontakt telefon prevoznika'
            }}>
              Možete kontaktirati prevoznika kako biste se dogovorili o svim detaljima.
              Prevozniku je takođe dostupan vaš telefon na koji vas može kontaktirati.
            </ImportantInfo>
          </Grid>
        }
        <Grid item container justifyContent={'center'}>
          <ButtonIconTop
            onClick={() => navigate(`/edit-post/${postId}`)}
            startIcon={<Edit/>}>
            Izmeni
          </ButtonIconTop>
          <ButtonIconTop
            startIcon={<CloseCircled/>}
            onClick={() => setShowCancelPostModal(true)}>
            Odustani
          </ButtonIconTop>
        </Grid>
        <Grid item>
          <InstallAppButton/>
        </Grid>
      </Grid>
      <Modal open={showCancelPostModal}>
        <ModalBox>
          <ModalTitle title={'Molimo za potvrdu'} onClose={() => setShowCancelPostModal(false)}/>
          <Typography>
            Da li ste sigurni da želite da odustanete od {hasAcceptedOffer ? 'ponude' : 'objave'}?
          </Typography>
          <ModalActions container>
            <Grid item>
              <Button size={'small'} color={'inherit'} onClick={() => setShowCancelPostModal(false)}>Odustani</Button>
            </Grid>
            <Grid item style={{marginLeft: 8}}>
              <Button
                size={'small'}
                color={'secondary'}
                onClick={async () => {
                  if (!postId) {
                    return
                  }
                  if (!hasAcceptedOffer) {
                    await cancelPost(Number(postId))
                    navigate('/my-posts')
                  } else {
                    await cancelAcceptedOffer(Number(postId))
                  }
                  setShowCancelPostModal(false)
                }}>
                Potvrdi
              </Button>
            </Grid>
          </ModalActions>
        </ModalBox>
      </Modal>
    </PostDetailsWrapper>
  )
}
