import { FC, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { LoginContainer } from 'forms/LoginForm/LoginForm.styles'
import { SMSLoginForm } from 'forms/LoginForm/SMSLoginForm'
import { EmailLoginForm } from 'forms/LoginForm/EmailLoginForm'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLoginModal } from 'redux/slices/appSlice'
import {
  markCreateAfterLogin,
  reset,
  usePostSlice
} from 'redux/slices/postSlice'
import { useNavigate } from 'react-router-dom'
import {
  useCreatePostMutation
} from 'services/api/postsApi'

export const LoginForm: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { createAfterLogin, ...postData } = useSelector(usePostSlice)
  const [createPost] = useCreatePostMutation()
  const onComplete = async () => {
    if (createAfterLogin) {
      await createPost(postData)
    }
    dispatch(toggleLoginModal())
    dispatch(reset())
    dispatch(markCreateAfterLogin(false))
    navigate('/my-posts')
  }
  const [form, setForm] =
    useState<'selection' | 'smsLogin' | 'emailLogin' | 'smsReg' | 'emailReg'>('selection')
  const onBack = () => setForm('selection')
  return (
    <LoginContainer container spacing={2} flexDirection={'column'}>
      {
        form === 'selection' &&
        <>
          <Grid item container>
            <Typography variant={'h5'}>
              Izaberi način prijave
            </Typography>
          </Grid>
          <Grid item container spacing={2} justifyContent={'center'}
                flexDirection={'column'} marginTop={4}>
            <Grid item container justifyContent={'center'}>
              <Typography>
                Ako već koristiš Kombi Nacija aplikaciju prijavi se:
              </Typography>
            </Grid>
            <Grid item container spacing={2} flexDirection={'column'}>
              <Grid item>
                <Button color={'secondary'} variant={'outlined'}
                        onClick={() => setForm('smsLogin')}>
                  Pomoću broja telefona (SMS kod)
                </Button>
              </Grid>
              <Grid item>
                <Button color={'secondary'} variant={'outlined'}
                        onClick={() => setForm('emailLogin')}>
                  Pomoću email-a i lozinke
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={2} flexDirection={'column'}
                  marginTop={4}>
              <Grid container justifyContent={'center'}>
                <Typography>
                  Nisi do sada koristio Kombi Nacija aplikaciju?
                </Typography>
              </Grid>
              <Grid item>
                <Button color={'secondary'} onClick={() => setForm('emailReg')}>
                  Kreiraj novi nalog
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      {
        form === 'smsLogin' &&
        <SMSLoginForm onBack={onBack} onComplete={onComplete}/>
      }
      {
        (form === 'emailLogin' || form === 'emailReg') &&
        <EmailLoginForm onBack={onBack} onComplete={onComplete}
                        isReg={form === 'emailReg'}/>
      }
    </LoginContainer>
  )
}
