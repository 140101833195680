import { FC } from 'react'
import { Grid } from '@mui/material'
import { Star } from 'icons/Star'

export interface RatingProps {
  rating: number
  onRate?(rate: number): void
}
export const Rating: FC<RatingProps> = ({ rating, onRate }) => {
  const r = Math.round(rating)
  const rate = (r: number) => () => {
    onRate && onRate(r)
  }
  return (
    <Grid container spacing={0.5}>
      <Grid item>
        <Star fill={r >= 1 ? '#ff0' : '#fff'} onClick={rate(1)}/>
      </Grid>
      <Grid item>
        <Star fill={r >= 2 ? '#ff0' : '#fff'} onClick={rate(2)}/>
      </Grid>
      <Grid item>
        <Star fill={r >= 3 ? '#ff0' : '#fff'} onClick={rate(3)}/>
      </Grid>
      <Grid item>
        <Star fill={r >= 4 ? '#ff0' : '#fff'} onClick={rate(4)}/>
      </Grid>
      <Grid item>
        <Star fill={r >= 5 ? '#ff0' : '#fff'} onClick={rate(5)}/>
      </Grid>
    </Grid>
  )
}
