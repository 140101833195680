import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export interface AppState {
  appLoading: boolean
  statusBarColor: string
  phoneNumber: string
  acceptedTerms: boolean
  name: string
  email: string
  showLoginModal: boolean
  userRatingId: number | null
}

const initialState: AppState = {
  appLoading: true,
  statusBarColor: '#FF5B7F',
  phoneNumber: '',
  acceptedTerms: false,
  name: '',
  email: '',
  showLoginModal: false,
  userRatingId: null
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: state => {
      state.appLoading = true
    },
    appLoaded: state => {
      state.appLoading = false
    },
    setStatusBarColor: (state, { payload }: PayloadAction<string>) => {
      state.statusBarColor = payload
    },
    setPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.phoneNumber = payload
    },
    setAcceptedTerms: (state, { payload }: PayloadAction<boolean>) => {
      state.acceptedTerms = payload
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload
    },
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload
    },
    toggleLoginModal: (state) => {
      state.showLoginModal = !state.showLoginModal
    },
    setUserRatingId: (state, { payload }: PayloadAction<number | null>) => {
      state.userRatingId = payload
    }
  }
})

export const {
  setAppLoading,
  appLoaded,
  setStatusBarColor,
  setPhoneNumber,
  setAcceptedTerms,
  setName,
  setEmail,
  toggleLoginModal,
  setUserRatingId
} = appSlice.actions

export const useAppSlice = (state: RootState) => state.app

export const useStatusBarColor = (state: RootState) => state.app.statusBarColor

export default appSlice.reducer
