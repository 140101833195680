import { Button } from '@mui/material'
import { GooglePlay } from 'icons/GooglePlay'
import { FC } from 'react'

export const InstallAppButton: FC = () => {
  return (
    <Button
      variant={'outlined'}
      endIcon={<GooglePlay/>}
      target={'_blank'}
      href={'https://play.google.com/store/apps/details?id=com.kombinacija'}>
      Za više opcija i bolje iskustvo preuzmi aplikaciju
    </Button>
  )
}
