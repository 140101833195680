import { FC, SVGProps } from 'react'

export const InfoCircleFull: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 100 100" {...props}>
      <g id="Group_2210" data-name="Group 2210" transform="translate(-65 -499)">
        <path id="Icon_ionic-ios-information-circle" data-name="Icon ionic-ios-information-circle" d="M53.375,3.375a50,50,0,1,0,50,50A49.992,49.992,0,0,0,53.375,3.375Zm4.567,73.077H48.76V41.812h9.183ZM53.351,38.038a4.8,4.8,0,1,1,4.976-4.808A4.818,4.818,0,0,1,53.351,38.038Z" transform="translate(61.625 495.625)" fill="rgba(136,144,166,0.5)"/>
      </g>
    </svg>
  )
}

