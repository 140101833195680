import { FC, useState } from 'react'
import { OfferStatus, OfferWithCarrier } from 'types'
import { Button, Grid, Modal, styled, Typography } from '@mui/material'
import { Avatar } from 'components/Avatar/Avatar'
import { getLocalDate } from 'utils/dates'
import { Price } from 'components/Price/Price'
import { ModalActions, ModalBox, ModalTitle } from 'components/Modal/Modal'
import { ImportantInfo } from 'components/ImportantInfo/ImportantInfo'
import { useAcceptOfferMutation } from 'services/api/postsApi'

const Form = styled(Grid)`
  //width: 500px
`

const CancelButton = styled(Button)`
  margin-right: 24px;
`

const InfoContainer = styled(Grid)`
  margin-top: 32px;
`

export interface AcceptOfferFormInterface {
  offer: OfferWithCarrier
}
export const AcceptOfferForm: FC<AcceptOfferFormInterface> = ({ offer }) => {
  const [showForm, setShowForm] = useState(false)
  const [acceptOffer] = useAcceptOfferMutation()
  const { carrier } = offer
  const { user } = carrier
  if (offer.status === OfferStatus.ACCEPTED) {
    return null
  }
  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        fullWidth={false}
        color={'secondary'}
        size={'small'}>
        Prihvati
      </Button>
      <Modal open={showForm}>
        <ModalBox>
          <Form container direction={'column'} spacing={2}>
            <ModalTitle
              title={'Da li ste sigurni da prihvatate ovu ponudu?'}
              onClose={() => setShowForm(false)}/>
            <Grid item>
              <Avatar
                src={user.avatarUri}
                date={getLocalDate(new Date(offer.createdAt))}
                name={user.name}
                rate={user.rate ?? 0}/>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>{offer.comment}</Typography>
            </Grid>
            <Grid item>
              <Price>{offer.price.toLocaleString('rs-SR')}</Price>
            </Grid>
            <InfoContainer item>
              <ImportantInfo title={'VAŽNO JE DA ZNATE'}>
                Nakon prihvatanja ove ponude sve ostale ponude više neće biti dostupne.
                Kada prihvatite ponudu moći ćete direktno da komunicirate sa prevoznikom
                kako biste se dogovorili oko detalja transporta.
              </ImportantInfo>
            </InfoContainer>
            <ModalActions item container>
              <Grid item>
                <CancelButton
                  onClick={() => setShowForm(false)}
                  fullWidth={false}
                  variant={'outlined'}
                  size={'small'}>
                  Odustani
                </CancelButton>
              </Grid>
              <Grid item>
                <Button
                  onClick={async () => {
                    await acceptOffer({postId: offer.postId, offerId: offer.id})
                    setShowForm(false)
                  }}
                  fullWidth={false}
                  color={'secondary'}
                  size={'small'}>
                  Prihvati
                </Button>
              </Grid>
            </ModalActions>
          </Form>
        </ModalBox>
      </Modal>
    </>
  )
}
