import { FC, useState } from 'react'
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth'
import { BasicLoginForm } from 'forms/LoginForm/LoginFormTypes'
import {
  Button, FormControlLabel,
  Grid,
  Stack,
  styled, Switch,
  TextField,
  Typography
} from '@mui/material'
import { isEmail } from 'utils/helpers'
import { auth } from 'initFirebase'
import { useUpdateUserMutation } from 'services/api/usersApi'

const ResetHere = styled(Typography)`
  cursor: pointer;
  font-weight: bold;
`

const FCLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};
`

interface EmailLoginFormProps extends BasicLoginForm {
  isReg?: boolean
}

export const EmailLoginForm: FC<EmailLoginFormProps> = (
  { onBack, isReg = false, onComplete }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [passResetMode, setPassResetMode] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailSentSuccess, setEmailSentSuccess] = useState<boolean>(false)
  const [acceptTerms, setAcceptTerms] = useState(false)

  const [updateUser] = useUpdateUserMutation()

  const onNext = async () => {
    if (isReg && !name) {
      setNameError('Ovo polje je obavezno')
      return
    }
    if (email && !isEmail(email)) {
      setEmailError('Neispravna email adresa')
      return
    }
    if (passResetMode) {
      try {
        await sendPasswordResetEmail(auth, email)
        setEmailSentSuccess(true)
      } catch (error) {
        const { code } = error as { code: string }
        switch (code) {
          case 'auth/user-not-found':
            setEmailError('Nepostojeći korisnik')
            break
        }
      }
    } else {
      try {
        if (!isReg) {
          await signInWithEmailAndPassword(auth, email, password)
        } else {
          await createUserWithEmailAndPassword(auth, email, password)
          await updateUser({
            name, email, userType: 'customer', wantsOffersOnMail: true, acceptedTermsAndConditions: true
          })
        }
        onComplete()
      } catch (error) {
        const { code } = error as { code: string }
        switch (code) {
          case 'auth/wrong-password':
            setPasswordError('Pogrešna šifra')
            break
          case 'auth/user-not-found':
            setEmailError('Nepostojeći korisnik')
            break
          case 'auth/email-already-in-use':
            setEmailError('Email već u upotrebi')
            break
        }
      }
    }
  }

  return (
    <Grid container item spacing={2} flexDirection={'column'}>
      <Grid item container marginBottom={2}>
        <Typography variant={'h5'}>
          {!isReg ? `Unesite email ${passResetMode ? '' : ' i lozinku'}` : 'Unesite podatke za prijavu'}
        </Typography>
      </Grid>
      {isReg &&
        <Grid item>
          <TextField
            label={'Ime i prezime'}
            type={'name'}
            error={!!nameError}
            helperText={nameError}
            onChange={(e) => {
              setName(e.target.value)
            }}/>
        </Grid>
      }
      <Grid item>
        <TextField
          label={'Email adresa'}
          value={email}
          type={'email'}
          error={!!emailError}
          helperText={emailError}
          onChange={(e) => {
            setEmail(e.target.value)
          }}/>
      </Grid>
      {!passResetMode && <Grid item>
        <TextField
          label={'Lozinka'}
          value={password}
          type={'password'}
          error={!!passwordError}
          helperText={passwordError}
          onChange={(e) => {
            setPassword(e.target.value)
          }}/>
      </Grid>}
      {!passResetMode && isReg && <Grid item>
        <TextField
          label={'Ponovi lozinku'}
          value={repeatPassword}
          type={'password'}
          error={!!passwordError}
          helperText={passwordError}
          onChange={(e) => {
            setRepeatPassword(e.target.value)
          }}/>
        {isReg && <Grid item marginTop={2}>
          <FCLabel
            control={
              <Stack direction={'row'} alignItems={'center'}>
                <Typography>Ne</Typography>
                <Switch
                  value={acceptTerms}
                  onChange={(e, checked) => {
                    setAcceptTerms(checked)
                  }}/>
                <Typography>Da</Typography>
              </Stack>
            }
            labelPlacement={'start'}
            label={'Prihvatam navedene uslove korišćenja'}/>
        </Grid>}
      </Grid>}
      <Grid container item spacing={2} flexDirection={'column'} marginTop={4}>
        {!passResetMode && !isReg &&
          <Grid item container flexDirection={'column'}>
            <Grid item><Typography color={'secondary'}>Zaboravili ste
              lozinku?</Typography></Grid>
            <Grid item>
              <ResetHere
                color={'secondary'}
                onClick={() => setPassResetMode(true)}>
                Resetujte je ovde
              </ResetHere>
            </Grid>
          </Grid>
        }
        {
          emailSentSuccess &&
          <Grid item>
            <Typography>Email za resetovanje lozinke je uspešno
              poslat.</Typography>
          </Grid>
        }
        <Grid item>
          <Button color={'secondary'} onClick={onNext}
                  disabled={(passResetMode && emailSentSuccess) || (isReg && !acceptTerms)}>
            Nastavi
          </Button>
        </Grid>
        <Grid item>
          <Button color={'secondary'} variant={'outlined'} onClick={() => {
            if (!passResetMode) {
              onBack()
            } else {
              setPassResetMode(false)
              setEmailSentSuccess(false)
            }
          }}>
            {passResetMode ? 'Nazad na stranu za logovanje' : 'Nazad'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
