import { FC, useCallback } from 'react'
import { Grid } from '@mui/material'
import { ServiceType } from 'components/ServiceType/ServiceType'
import { Couch } from 'icons/Couch'
import { Passenger } from 'icons/Passenger'
import { House } from 'icons/House'
import { Car } from 'icons/Car'
import { Trash } from 'icons/Trash'
import { useDispatch, useSelector } from 'react-redux'
import { setServiceType, usePostSlice } from 'redux/slices/postSlice'
import { Service } from 'types'

export const ServicePickForm: FC = () => {
  const dispatch = useDispatch()
  const { serviceType } = useSelector(usePostSlice)
  const onSelect = useCallback((service: Service) => {
    dispatch(setServiceType(service))
  }, [dispatch])
  const isSelected = useCallback((service: Service) => {
    return service === serviceType
  }, [serviceType])
  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item>
        <ServiceType
          icon={<Couch/>}
          title={'Prevoz stvari'}
          isActive={isSelected('GOOD_TRANSPORT')}
          onClick={() => onSelect('GOOD_TRANSPORT')}/>
      </Grid>
      <Grid item>
        <ServiceType
          icon={<Passenger/>}
          title={'Prevoz putnika'}
          isActive={isSelected('PUBLIC_TRANSPORT')}
          onClick={() => onSelect('PUBLIC_TRANSPORT')}/>
      </Grid>
      <Grid item>
        <ServiceType
          icon={<House/>}
          title={'Selidba'}
          isActive={isSelected('MOVING')}
          onClick={() => onSelect('MOVING')}/>
      </Grid>
      <Grid item>
        <ServiceType
          icon={<Car/>}
          title={'Prevoz vozila'}
          isActive={isSelected('VEHICLE_TRANSPORT')}
          onClick={() => onSelect('VEHICLE_TRANSPORT')}/>
      </Grid>
      <Grid item>
        <ServiceType
          icon={<Trash/>}
          title={'Odnošenje viška'}
          isActive={isSelected('GARBAGE_DISPOSAL')}
          onClick={() => onSelect('GARBAGE_DISPOSAL')}/>
      </Grid>
    </Grid>
  )
}
