import { FC, useEffect, useState } from 'react'
import { useCarriersQuery } from 'services/api/api'
import { Button, Grid, styled, Typography } from '@mui/material'
import { Avatar } from 'components/Avatar/Avatar'
import { CarriersPublic } from 'types'
import { useDispatch } from 'react-redux'
import { setUserRatingId } from 'redux/slices/appSlice'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'initFirebase'
import { hidePhoneNumber } from 'utils/helpers'
import { useLocation } from 'react-router-dom'

const CarrierRow = styled(Grid)`
  border-bottom: 1px dashed #8890A6;
  padding: 16px !important;
`

export const CarriersWidget: FC = () => {
  const [user] = useAuthState(auth)
  const dispatch = useDispatch()
  const [page, setPage] = useState<number>(1)
  const [loadedCarriers, setLoadedCarriers] =
    useState<CarriersPublic[]>([])
  const { data, isLoading, fulfilledTimeStamp } = useCarriersQuery(page)
  const { carriers, total } = data ?? { total: 0, carriers: [] }
  const {pathname } = useLocation()

  useEffect(() => {
    setLoadedCarriers(Array.from(new Set<CarriersPublic>([...loadedCarriers, ...(carriers ?? [])])))
  }, [fulfilledTimeStamp])

  if (pathname.indexOf('/post') === 0) {
    return null
  }
  if (!carriers || carriers.length === 0 || isLoading) {
    return null
  }
  return (
    <Grid container spacing={2}>
      <Grid item container justifyContent={'center'} marginBottom={2}
            marginTop={4}>
        <Typography variant={'h2'}>Prevoznici na kombinacija aplikaciji
          ({total})</Typography>
      </Grid>
      {
        loadedCarriers.map((carrier) => (
          <CarrierRow item container key={carrier.userId}>
            <Grid item container justifyContent={'space-between'}>
              <Grid item>
                <Avatar
                  src={carrier.avatarURL}
                  name={carrier.name}
                  rate={carrier.userRatings}/>
              </Grid>
              {
                user &&
                <Grid item>
                  <Button href={'#'}
                          color={'secondary'} variant={'text'}
                          onClick={() => dispatch(setUserRatingId(carrier.userId))}>
                    Detalji i ocene
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid item>
              <Typography
                variant={'caption'}>
                {hidePhoneNumber(carrier.servicesDescription ?? '')}
              </Typography>
            </Grid>
          </CarrierRow>
        ))
      }
      {loadedCarriers.length < total &&
        <Grid item container justifyContent={'center'} marginTop={4}>
          <Grid item xs={8}>
            <Button variant={'outlined'} onClick={() => setPage(page + 1)}>Prikaži
              još prevoznika</Button>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}
