import {FC} from 'react'
import { Grid, styled } from '@mui/material'

const LoaderWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
`

export const Loader: FC = () => {
  return <LoaderWrapper container alignItems={'center'} justifyContent={'center'}><img src={'/images/loader.gif'} alt="loader" /></LoaderWrapper>
}
