import { FC, SVGProps } from 'react'

export const Couch: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47.143" height="30" viewBox="0 0 47.143 30" fill="#fff" {...props}>
      <path id="ic_weekend_24px" d="M43.857,15.714A4.3,4.3,0,0,0,39.571,20v6.429h-30V20A4.286,4.286,0,0,0,1,20V30.714A4.3,4.3,0,0,0,5.286,35H43.857a4.3,4.3,0,0,0,4.286-4.286V20A4.3,4.3,0,0,0,43.857,15.714ZM37.429,5H11.714A4.3,4.3,0,0,0,7.429,9.286v4.607a6.409,6.409,0,0,1,4.286,6.043v4.35H37.429v-4.35a6.451,6.451,0,0,1,4.286-6.043V9.286A4.3,4.3,0,0,0,37.429,5Z" transform="translate(-1 -5)" />
    </svg>

  )
}
