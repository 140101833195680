import { FC, ReactElement } from 'react'
import { Grid, styled, Typography } from '@mui/material'

const ServiceTypeContainer = styled(Grid)<{ active: number }>((
  {
    theme,
    active
  }) => `
  background-color: ${!active ? theme.palette.grey['500'] : theme.palette.secondary.main};
  border-radius: 20px;
  color: ${theme.palette.text.secondary};
  height: 50px;
  padding-left: 30px;
  padding-right: 22px;
  cursor: pointer;
`)

const Title = styled(Typography)`
  font-family: Poppins-Bold;
`

export interface ServiceTypeProps {
  title: string
  icon: ReactElement
  isActive: boolean
  onClick: () => void
}

export const ServiceType: FC<ServiceTypeProps> = (
  {
    title,
    icon,
    isActive,
    onClick
  }) => {
  return (
    <ServiceTypeContainer
      active={isActive ? 1 : 0}
      container
      onClick={onClick}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Grid item xs>
        <Title>{title}</Title>
      </Grid>
      <Grid item>
        {icon}
      </Grid>
    </ServiceTypeContainer>
  )
}
