import { FC, useEffect, useState } from 'react'
import {
  useAddUserRatingMutation,
  useGetPendingUserRatingsQuery
} from 'services/api/userRatingsApi'
import { ModalBox, ModalTitle } from 'components/Modal/Modal'
import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { Avatar } from 'components/Avatar/Avatar'
import { InfoCircleFull } from 'icons/InfoCircleFull'
import { Loader } from 'components/Loader/Loader'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'initFirebase'

export const RateUserWidget: FC = () => {
  const [user] = useAuthState(auth)
  const { data: pendingRatings } = useGetPendingUserRatingsQuery(undefined, {skip: !user})
  const [showModal, setShowModal] = useState(false)
  const [ratedUsers, setRatedUsers] = useState<number[]>([])
  const [rate, setRate] = useState(0)
  const [comment, setComment] = useState('')
  const [addUserRating, { isLoading }] = useAddUserRatingMutation()

  useEffect(() => {
    if (pendingRatings && pendingRatings.length > 0) {
      setShowModal(true)
    }
  }, [pendingRatings])

  if (!pendingRatings || !user) {
    return null
  }

  const cpr = pendingRatings.filter(
    _ => !ratedUsers.includes(_.id))[0] ?? null

  if (cpr === null) {
    return null
  }

  const rateUser = async () => {
    await addUserRating({
      comment,
      rate,
      userId: cpr.userId,
      postId: cpr.postId
    })
    setRatedUsers([...ratedUsers, cpr.id])
    setComment('')
    setRate(0)
  }

  return (
    <Modal
      disableEscapeKeyDown
      open={showModal}>
      {
        isLoading ? <ModalBox><Loader/></ModalBox>:
        <ModalBox>
          <ModalTitle
            title={'Molimo Vas da ocenite korisnika'}
            onClose={() => setShowModal(false)}/>
          <Grid container item flexDirection={'column'} marginTop={2}>
            <Grid item>
              <Typography color={'secondary'} marginBottom={2}>
                Vaša ocena
              </Typography>
            </Grid>
            <Grid item>
              <Avatar src={cpr.user.avatarUri} name={cpr.user.name} rate={rate} onRate={setRate}/>
            </Grid>
            <Grid item container marginTop={2} >
              <Typography variant={'caption'} marginRight={1}>Možete izabrati odgovarajuću zvezdicu</Typography>
              <InfoCircleFull/>
            </Grid>
            <Grid item marginTop={2}>
              <Typography variant={'body2'}>Vaš komentar</Typography>
              <TextField
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value)
                }}
                multiline
                rows={4}
                variant={'outlined'}/>
            </Grid>
            <Grid item marginTop={2}>
              <Button color={'secondary'} onClick={rateUser}>
                Oceni
              </Button>
            </Grid>
          </Grid>
        </ModalBox>
      }
    </Modal>
  )
}
