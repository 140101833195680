import { FC } from 'react'
import {
  Fab,
  Grid,
  styled
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { PostCard } from 'components/PostCard/PostCard'
import { useNavigate } from 'react-router-dom'
import { useGetPostsQuery } from 'services/api/postsApi'

const PostsList = styled(Grid)`
  margin-bottom: 30px;
  padding-inline: 8px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 24px;
  }
`

export const PostsPage: FC = () => {
  const navigate = useNavigate()
  const { data: posts = [] } = useGetPostsQuery()
  return (
    <Grid container item flexDirection={'column'} alignItems={'center'} marginBottom={4}>
      <PostsList container spacing={4}>
        {
          posts.map((post) => (
            <Grid item xs={12} sm={6} key={post.id}>
              <PostCard post={post}/>
            </Grid>
          ))
        }
      </PostsList>
      <Grid item>
        <Fab
          onClick={() => navigate('/')}
          variant={'extended'}>
          <AddIcon/>Dodaj Novu Objavu
        </Fab>
      </Grid>
    </Grid>
  )
}
