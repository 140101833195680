import {
  Address,
  GeocoderAddress,
  GeocoderAddressComponent,
  GeocoderAddressComponentType,
  Nullable
} from 'types'

export const parseGeocoderAddress = (
  address: GeocoderAddress
): Nullable<Address> => {
  if (!address) {
    return null
  }
  const addressComponents = address.address_components
  const streetNumber =
    findInAddressComponent(addressComponents, 'street_number')?.long_name ?? ''
  const streetName =
    findInAddressComponent(addressComponents, 'route')?.long_name ?? ''
  const locality =
    findInAddressComponent(addressComponents, 'locality')?.long_name ?? ''
  const postalCode =
    findInAddressComponent(addressComponents, 'postal_code')?.long_name ?? ''
  const establishment =
    findInAddressComponent(addressComponents, 'establishment')?.long_name ?? ''
  const countryComponent = findInAddressComponent(addressComponents, 'country')
  const countryName = countryComponent?.long_name ?? ''
  const countryCode = countryComponent?.short_name ?? ''
  const lat = address.geometry.location.lat
  const lng = address.geometry.location.lng
  return {
    streetNumber,
    streetName,
    locality,
    postalCode,
    countryName,
    countryCode,
    lat,
    lng,
    hasElevator: false,
    floor: '',
    establishment
  }
}

export const findInAddressComponent = (
  addressComponent: GeocoderAddressComponent[],
  key: GeocoderAddressComponentType
): GeocoderAddressComponent | undefined => {
  return addressComponent.find(ac => ac.types.includes(key))
}

export const formatAddress = (address: Address): string => {
  const locality = [
    address.establishment,
    address.postalCode,
    address.locality
  ].join(' ')
  if (!address.streetNumber && !address.streetName) {
    return locality.trim()
  }
  return [[address.streetName, address.streetNumber].join(' '), locality].join(
    ', '
  ).trim()
}

// export const latLngDelta = (
//   addressA: Nullable<Address>,
//   addressB: Nullable<Address>
// ): Geocoder.LatLng | undefined => {
//   if (!addressA || !addressB) {
//     return undefined
//   }
//   const latDelta = Math.abs(addressA.lat - addressB.lat)
//   const lngDelta = Math.abs(addressA.lng - addressB.lng)
//   return {
//     lat: latDelta * 1.5,
//     lng: lngDelta * 1.5
//   }
// }
//
// export const centerLatLng = (
//   addressA: Nullable<Address>,
//   addressB: Nullable<Address>
// ): Geocoder.LatLng | undefined => {
//   if (addressA && addressB) {
//     return {
//       lat: (addressA.lat + addressB.lat) / 2,
//       lng: (addressA.lng + addressB.lng) / 2
//     }
//   }
//   if (addressA && !addressB) {
//     return addressA
//   }
//   if (addressB && !addressA) {
//     return addressB
//   }
//   return undefined
// }
