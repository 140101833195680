import { createTheme } from '@mui/material'

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#515C6F'
    },
    secondary: {
      main: '#FD7E77',
      dark: '#F17972',
      contrastText: '#FFFFFF'
    },
    background: {
      paper: '#FFFFFF'
    },
    grey: {
      '400': '#C3C7D2',
      '500': '#B9BDC8',
      '600': '#8890A6',
      '700': '#707070'
    },
    text: {
      primary: '#515C6F',
      secondary: '#FFFFFF'
    },
    warning: {
      main: '#FFB85B'
    }
  },
  typography: {
    fontFamily: [
      'Poppins-Medium',
      'Roboto'
    ].join(',')
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: ({ theme }) => ({
          fontFamily: 'Poppins-Bold',
          fontSize: '1.75rem',
          color: theme.palette.text.secondary
        }),
        h2: ({ theme }) => ({
          fontFamily: 'Poppins-Bold',
          fontSize: '1.5rem',
          color: theme.palette.text.primary
        }),
        h3: ({theme}) => ({
          fontFamily: 'Poppins-Bold',
          fontSize: '1.5rem',
          color: theme.palette.grey['600']
        }),
        h4: ({ theme }) => ({
          fontFamily: 'Poppins-Bold',
          fontSize: '1.5rem',
          color: theme.palette.secondary.main
        }),
        subtitle1: ({ theme }) => ({
          fontFamily: 'Poppins-Regular',
          fontSize: '1.125rem',
          color: theme.palette.text.secondary,
          letterSpacing: -0.43
        }),
        body2: ({ theme }) => ({
          fontSize: '0.875rem',
          color: theme.palette.secondary.main
        }),
        caption: ({theme}) => ({
          color: theme.palette.grey['600'],
          fontSize: '0.75rem',
          fontFamily: 'Poppins-Regular',
          whiteSpace: 'pre-line'
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        focused: true,
        fullWidth: true,
        InputLabelProps: {
          color: 'secondary'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({theme }) => ({
          borderColor: `${theme.palette.grey['400']} !important`,
          borderWidth: '1px !important',
          borderRadius: 5
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: ({ theme }) => ({
          ':after': {
            borderBottomColor: theme.palette.secondary.main
          }
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'Poppins-Regular'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          textTransform: 'none',
          height: 50
        },
        sizeSmall: {
          height: 30,
          borderRadius: 15,
          paddingInline: 32
        }
      },
      defaultProps: {
        variant: 'contained',
        fullWidth: true
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: ({theme}) => ({
          borderRadius: 15,
          backgroundColor: theme.palette.grey?.['500'],
          minHeight: 36
        })
      },
      defaultProps: {
        TabIndicatorProps: {
          style: { backgroundColor: 'transparent' }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey?.['500'],
          textTransform: 'none',
          paddingBlock: 6,
          minHeight: 36,
          '&.Mui-selected': {
            borderRadius: 15,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.secondary
          }
        })
      }
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        thumb: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.warning.main,
          fontFamily: 'Poppins-Regular',
          fontSize: '0.75rem'
        })
      }
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark
          }
        })
      }
    }
  }
})
