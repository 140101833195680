import { FC, SVGProps } from 'react'

export const Passenger: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31.58" height="30" viewBox="0 0 31.58 30" fill="#fff" {...props}>
      <path id="ic_airline_seat_recline_extra_24px" d="M7.29,7.747A3.163,3.163,0,1,1,11.7,6.973a3.178,3.178,0,0,1-4.405.774Zm16.816,21.1H12.942a4.723,4.723,0,0,1-4.674-4.011L5.158,9.895H2L5.142,25.306A7.911,7.911,0,0,0,12.958,32H24.106Zm.363-6.316H16.764l-1.626-6.474c2.495,1.405,5.179,2.432,8.132,1.926V14.616a9.621,9.621,0,0,1-7.406-1.974l-2.59-2.005a3.572,3.572,0,0,0-1.2-.6,3.489,3.489,0,0,0-1.563-.095h-.032a3.581,3.581,0,0,0-2.905,4.121l2.132,9.348a4.75,4.75,0,0,0,4.658,3.853H25.18L31.211,32l2.368-2.368Z" transform="translate(-2 -2.001)" />
    </svg>

  )
}
