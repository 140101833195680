import { FC, SVGProps } from 'react'

export const GooglePlay: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31.427" height="35" viewBox="0 0 31.427 35" fill="#515c6f" {...props}>
      <g id="googleplay" transform="translate(-2.45 0)">
        <path id="Path_1323" data-name="Path 1323" d="M32.773,19.393l-5.715,3.235-5.126-5.094L27.1,12.4l5.674,3.211a2.173,2.173,0,0,1,0,3.783ZM2.613,1.348a2.167,2.167,0,0,0-.163.828v30.65a2.178,2.178,0,0,0,.181.875L18.9,17.532Zm17.8,14.678L25.167,11.3,5.695.284A2.138,2.138,0,0,0,4.315.023Zm0,3.014L4.374,34.984a2.132,2.132,0,0,0,1.321-.267l19.431-11-4.71-4.681Z" transform="translate(0 0)" />
      </g>
    </svg>

  )
}
