import {
  Box,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material'
import { FC } from 'react'
import { Close } from 'icons/Close'

export const ModalBox = styled(Box)`
  position: absolute;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
  }
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 32px;
`

export interface ModalTitleProps {
  title: string
  onClose: () => void
}
export const ModalTitle: FC<ModalTitleProps> = ({ title, onClose }) => {
  return (
    <Grid container alignItems={'center'}>
      <Grid item xs>
        <Typography color={'secondary'} variant={'subtitle1'}>{ title }</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={onClose}>
          <Close/>
        </IconButton>
      </Grid>
    </Grid>
  )
}

export const ModalActions = styled(Grid)`
  margin-top: 16px;
  justify-content: flex-end;
`
