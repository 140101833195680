export const isEmail = (email: string): boolean => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}

const phoneReg =/[+]?[(]?[0-9]{3}[)]?[-\s./]?[0-9]{3}[-\s.]?[0-9]{4,6}/gim
const phoneReg2 =/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gim
const phoneReg3 =/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1, 3})[-. )]*(\d{1, 3})[-. ]*(\d{1, 4})(?: *x(\d+))?\s*$/gim
const phoneReg4 =/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gim
const phoneReg5 =/[+(]?\d(?:[-()+\s.\/]*\d){8,14}(?![-()+\s.]*\d)/
export const hidePhoneNumber = (text: string): string => {
  return text.replace(phoneReg, '*******')
  .replace(phoneReg2, '*******')
  .replace(phoneReg3, '*******')
  .replace(phoneReg4, '*******')
  .replace(phoneReg5, '*******')
}
