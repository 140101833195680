import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import { mainTheme } from 'themes/mainTheme'
import { Wrapper } from 'components/Wrapper/Wrapper'
import { MainRoute } from 'routes/MainRoute'
import { store } from 'redux/store'
import 'Geocode'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, RecaptchaVerifier } from 'initFirebase'
import { Loader } from 'components/Loader/Loader'
import { CarriersWidget } from 'widgets/CarriersWidget/CarriersWidget'
import { UserRatingsWidget } from 'widgets/UserRatingsWidget/UserRatingsWidget'
import { RateUserWidget } from 'widgets/RateUserWidget/RateUserWidget'

if (!window.recaptchaVerifier) {
  window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {size: 'invisible'}, auth)
  // window.recaptchaVerifier.render().then(function (widgetId) {
  //   window.recaptchaWidgetId = widgetId
  //   rendered = true
  // }).catch((error) => {
  //   console.log('captcha error', error)
  // })
}
function App() {

  const authState= useAuthState(auth)
  if (authState[1]) {
    return <Loader/>
  }
  return (
    <Provider store={store}>
      <ThemeProvider theme={mainTheme}>
        <Wrapper>
          <MainRoute/>
          <CarriersWidget/>
          <UserRatingsWidget/>
          <RateUserWidget/>
        </Wrapper>
      </ThemeProvider>
    </Provider>
  )
}

export default App
