import { FC, useState } from 'react'
import VerificationInput from 'react-verification-input'
import { Button, Grid, styled, Typography } from '@mui/material'

const FormWrapper = styled(Grid)`
  text-align: center;
  justify-content: center;
  align-items: center;
`

const Verification = styled(Grid)`
  .vi__character {
    width: 2.25rem;
    height: 2.875rem;
    border-radius: 10px;
    border-width: 2px;
    border-color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 1.25rem;
    font-family: Poppins-Bold;
    line-height: 2.875rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .vi__character--inactive {
    background-color: transparent;
  }
  .vi__character--selected {
    outline: none;
    border-width: 3px;
  }
`

export interface PhoneConfirmFormProps {
  onSubmit: (code: string) => void
  onCancel: () => void
}
export const PhoneConfirmForm: FC<PhoneConfirmFormProps> = ({ onSubmit, onCancel }) => {
  const [code, setCode] = useState('')
  return (
    <FormWrapper container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'h3'}>
          Potrebno je još samo da <br/>verifikujete svoj broj telefona
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Unesite kod koji vam je stigao putem SMS poruke
        </Typography>
        <Typography>
          (NAPOMENA: Proverite i neželjene poruke)
        </Typography>
      </Grid>
      <Verification item>
        <VerificationInput
          value={code}
          onChange={setCode}
          placeholder={''}/>
      </Verification>
      <Grid item>
        <Typography>
          Prošlo je 2 minuta i poruka nije stigla?
        </Typography>
        <Button variant={'text'} color={'secondary'} onClick={onCancel}>
          Pokušaj još jednom
        </Button>
      </Grid>
      <Grid item container>
        <Button
          onClick={async () => {
            await onSubmit(code)
          }}
          color={'secondary'}>
          Potvrdi
        </Button>
      </Grid>
    </FormWrapper>
  )
}
