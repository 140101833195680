import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Address, Image, Post, PostImage, PostStatus, Service } from 'types'
import { RootState } from 'redux/store'

export interface PostState extends Post {
  images: Image[]
  removedImages: PostImage[]
  createAfterLogin: boolean
}

const initialState: PostState = {
  addressFrom: null,
  addressTo: null,
  images: [],
  removedImages: [],
  wantsCarriers: true,
  transportationServicesDescription: '',
  dimensionsDescription: '',
  distance: 0,
  serviceType: null,
  status: PostStatus.Offers,
  transportDate: '',
  createdAt: '',
  updatedAt: '',
  postImages: [],
  createAfterLogin: false
}

export const postSlice = createSlice({
  name: 'postSlice',
  initialState,
  reducers: {
    reset: () => initialState,
    setAddressFrom: (state, { payload }: PayloadAction<Address>) => {
      state.addressFrom = payload
    },
    setAddressTo: (state, { payload }: PayloadAction<Address>) => {
      state.addressTo = payload
    },
    setAddressFromFloor: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      if (state.addressFrom) {
        state.addressFrom.floor = payload
      }
    },
    setAddressToFloor: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      if (state.addressTo) {
        state.addressTo.floor = payload
      }
    },
    toggleAddressFromElevator: state => {
      if (state.addressFrom) {
        state.addressFrom.hasElevator = !state.addressFrom.hasElevator
      }
    },
    toggleAddressToElevator: state => {
      if (state.addressTo) {
        state.addressTo.hasElevator = !state.addressTo.hasElevator
      }
    },
    setTransportDate: (state, { payload }: PayloadAction<string>) => {
      state.transportDate = payload
    },
    setTransportationServicesDescription: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.transportationServicesDescription = payload
    },
    setDimensionsDescription: (state, { payload }: PayloadAction<string>) => {
      state.dimensionsDescription = payload
    },
    toggleWantsCarriers: state => {
      state.wantsCarriers = !state.wantsCarriers
    },
    setImage: (
      state,
      { payload }: PayloadAction<{ image: Image; index: number }>
    ) => {
      const { image, index } = payload
      state.images[index] = image
      if (state.postImages[index]) {
        state.postImages[index].imageUri = image.name // For replacing on server
      }
    },
    removeImage: (state, { payload }: PayloadAction<PostImage | undefined>) => {
      state.postImages = state.postImages.filter(_ => _.id !== payload?.id)
    },
    removeNewImage: (state, { payload }: PayloadAction<number>) => {
      state.images = state.images.filter((_, index) => index !== payload)
    },
    setDistance: (state, { payload }: PayloadAction<number>) => {
      state.distance = payload
    },
    setServiceType: (state, { payload }: PayloadAction<Service>) => {
      state.serviceType = payload
    },
    setState: (state, { payload }: PayloadAction<Post>) => {
      state = {
        ...payload,
        images: [],
        removedImages: [],
        createAfterLogin: false
      }
      return state
    },
    markCreateAfterLogin: (state, {payload}: PayloadAction<boolean>) => {
      state.createAfterLogin = payload
    }
  }
})

export const {
  reset,
  setAddressFrom,
  setAddressTo,
  setAddressToFloor,
  setAddressFromFloor,
  toggleAddressToElevator,
  toggleAddressFromElevator,
  setTransportationServicesDescription,
  setDimensionsDescription,
  toggleWantsCarriers,
  setImage,
  removeImage,
  removeNewImage,
  setDistance,
  setServiceType,
  setState,
  markCreateAfterLogin,
  setTransportDate
} = postSlice.actions

export default postSlice.reducer

export const usePostSlice = (state: RootState) => state.post
