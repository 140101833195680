import { FC, useCallback, useEffect, useState } from 'react'
import phone from 'phone'
import { Button, Grid, styled, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setPhoneNumber, useAppSlice } from 'redux/slices/appSlice'
import { CountryCode } from 'components/CountryCode/CountryCode'
import { ConfirmationResult, signInWithPhoneNumber, PhoneAuthProvider, updatePhoneNumber } from 'firebase/auth'
import { auth } from 'initFirebase'
import { Nullable } from 'types'
import { PhoneConfirmForm } from 'forms/PhoneConfirmForm/PhoneConfirmForm'
import { useUpdateUserMutation } from 'services/api/usersApi'

const CountryCodeSelect = styled(CountryCode)`
  position: absolute;
  height: 32px;
  width: 70px;
  left: 32px;
  bottom: 24px;

  .MuiInputBase-root {
    border: none;
  }
`
const PhoneRow = styled(Grid)`
  position: relative;
`

interface SMSLoginFormProps {
  onBack?: () => void
  onComplete: () => void
  usersPhone?: string
  isUpdate?: boolean
}

export const SMSLoginForm: FC<SMSLoginFormProps> = ({ isUpdate = false ,onBack, onComplete, usersPhone }) => {
  const dispatch = useDispatch()

  const p = phone(usersPhone ?? '')
  const cc = p.countryCode ?? '+381'
  const pn = p.phoneNumber ? p.phoneNumber.replace(cc, '') : ''
  const [updateUser] = useUpdateUserMutation()

  const [callingCode, setCallingCode] = useState(cc)
  const [_phone, setPhone] = useState(pn)
  const { phoneNumber } = useSelector(useAppSlice)
  const [confirmationResult, setConfirmationResult]
    = useState<Nullable<ConfirmationResult>>(null)
  const [verificationId, setVerificationId] = useState('')

  const [phoneChangeError, setPhoneChangeError] = useState('')

  const signIn = useCallback(async () => {
    if (!phoneNumber || phoneNumber.length < 10) {
      return
    }
    try {
      if (isUpdate) {
        const vId = await (new PhoneAuthProvider(auth)).verifyPhoneNumber(phoneNumber, window.recaptchaVerifier)
        setVerificationId(vId)
      } else {
        const confirmationResult = await signInWithPhoneNumber(
          auth, phoneNumber, window.recaptchaVerifier)
        setConfirmationResult(confirmationResult)
      }
    } catch (e) {
      console.error(e, 'signInWithPhoneNumber error')
    }
  }, [phoneNumber, isUpdate])

  useEffect(() => {
    dispatch(setPhoneNumber(`${callingCode}${_phone.replace(/^0+/, '')}`))
  }, [callingCode, _phone, dispatch])

  const showConfirmScreen = (isUpdate && verificationId) || (!isUpdate && confirmationResult)

  if (!showConfirmScreen) {
    return (
      <Grid item container spacing={2} flexDirection={'column'}>
        {
          phoneChangeError &&
          <Grid item>
            <Typography variant={'h3'} color={'error'}>
              {phoneChangeError}
            </Typography>
          </Grid>
        }
        <Grid item container marginBottom={2}>
          <Typography>
            Unesite broj telefona a zatim kod koji ste dobili u SMS-u
          </Typography>
        </Grid>
        <PhoneRow item>
          <TextField
            label={'Broj telefona'}
            value={phoneNumber.replace(cc, '')}
            onChange={(e) => {
              setPhone(e.target.value)
            }}
            InputProps={{
              style: { paddingLeft: 80 }
            }}
            helperText={'Obavezno polje'}/>
          <CountryCodeSelect
            value={cc}
            onChange={(e) => setCallingCode(e.target.value as string)}/>
        </PhoneRow>
        <Grid container item spacing={2} flexDirection={'column'} marginTop={4}>
          <Grid item>
            <Button color={'secondary'} onClick={signIn}>
              Nastavi
            </Button>
          </Grid>
          {
            onBack  &&
            <Grid item>
              <Button color={'secondary'} variant={'outlined'} onClick={onBack}>
                Nazad
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  } else {
    return (
      <PhoneConfirmForm
        onCancel={() => setConfirmationResult(null)}
        onSubmit={async (code) => {
          if (verificationId && auth.currentUser) {
            try {
              const phoneCreds = PhoneAuthProvider.credential(verificationId, code)
              await updatePhoneNumber(auth.currentUser, phoneCreds) // firebase update
              await updateUser({ // db update
                phoneNumber
              })
              onComplete()
            } catch (e) {
              const { code } = e as { code: string }
              switch(code) {
                case 'auth/account-exists-with-different-credential':
                  setPhoneChangeError('Već postoji nalog sa ovim brojem')
                  setVerificationId('')
              }
            }
          }
          if (confirmationResult) {
            try {
              await confirmationResult?.confirm(code)
              setConfirmationResult(null)
              onComplete()
            } catch (e) {
              console.error(e, 'confirm otp code error')
            }
          }
        }}/>
    )
  }
}
