import { FC, SVGProps } from 'react'

export const PhoneHandle: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <path id="Icon_awesome-phone-square-alt" data-name="Icon awesome-phone-square-alt" d="M44.643,2.25H5.357A5.357,5.357,0,0,0,0,7.607V46.893A5.357,5.357,0,0,0,5.357,52.25H44.643A5.357,5.357,0,0,0,50,46.893V7.607A5.357,5.357,0,0,0,44.643,2.25Zm-1.829,34.3L41.14,43.809a1.674,1.674,0,0,1-1.631,1.3A32.369,32.369,0,0,1,7.143,12.741a1.752,1.752,0,0,1,1.3-1.631L15.7,9.436a2.034,2.034,0,0,1,.376-.044,1.816,1.816,0,0,1,1.539,1.015l3.348,7.812a2,2,0,0,1,.135.66,1.9,1.9,0,0,1-.614,1.3l-4.229,3.46A25.883,25.883,0,0,0,28.615,36l3.46-4.229a1.9,1.9,0,0,1,1.3-.614,1.992,1.992,0,0,1,.66.135l7.812,3.348a1.814,1.814,0,0,1,1.015,1.539,1.943,1.943,0,0,1-.044.376Z" transform="translate(0 -2.25)" fill="rgba(136,144,166,0.5)"/>
    </svg>

  )
}

