import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Avatar } from 'components/Avatar/Avatar'
import { Price } from 'components/Price/Price'
import { OfferWithCarrier } from 'types'
import { getLocalDate } from 'utils/dates'
import { AcceptOfferForm } from 'forms/AcceptOfferForm'
import { hidePhoneNumber } from 'utils/helpers'

export interface OfferItemProps {
  offer: OfferWithCarrier
}
export const OfferItem: FC<OfferItemProps> = ({ offer }) => {
  const {carrier} = offer
  return (
    <Grid container direction={'column'} spacing={1}>
      <Grid item>
        <Avatar
          name={carrier.user.name}
          rate={carrier.user.rate ?? 0}
          date={getLocalDate(new Date(offer.createdAt))}
          src={carrier.user.avatarUri}/>
      </Grid>
      <Grid item>
        <Typography variant={'caption'}>
          {hidePhoneNumber(offer.comment)}
        </Typography>
      </Grid>
      <Grid item container justifyContent={'space-between'}>
        <Price>{offer.price.toLocaleString('rs-SR')}</Price>
        <AcceptOfferForm offer={offer}/>
      </Grid>
    </Grid>
  )
}
