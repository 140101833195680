import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CarriersPublic, Settings } from 'types'
import { auth } from 'initFirebase'

export const API_BASE_URL = 'https://api.kombinacija.app/' // @todo .ENV
// export const API_BASE_URL = 'http://localhost:8082/' // @todo .ENV

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: async headers => {
      const token = await auth.currentUser?.getIdToken()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  keepUnusedDataFor: 0,
  tagTypes: [
    'User',
    'Carrier',
    'Posts',
    'Post',
    'Offers',
    'Offer',
    'PendingRatings'
  ],
  endpoints: build => ({
    settings: build.query<Settings, void>({
      query: () => ({
        url: 'settings',
        method: 'GET'
      })
    }),
    carriers: build.query<{total: number, carriers: CarriersPublic[]}, number>({
      query: (page ) => ({
        url: `/carriers-public/${page}`,
        method: 'GET'
      })
    })
  })
})

export const { useSettingsQuery, useCarriersQuery } = api
