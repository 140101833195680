export type Nullable<T> = T | null

export interface Carrier {
  id: number
  userId: number
  user: User
  servicesDescription: string
  isLegalSubject: boolean
  hasInsurance: boolean
  carrierServices: CarrierService[]
}

export type Service =
  | 'GOOD_TRANSPORT'
  | 'MOVING'
  | 'GARBAGE_DISPOSAL'
  | 'PUBLIC_TRANSPORT'
  | 'VEHICLE_TRANSPORT'

export interface CarrierService {
  id: number
  carrierId: number
  service: Service
}

export type UserType = 'carrier' | 'customer' | null

export interface UserDevice {
  token: string
  wantsNotifications: boolean
}
export interface User {
  id: number
  name: string
  email: string
  phoneNumber: string
  userType: Nullable<UserType>
  acceptedTermsAndConditions: boolean
  avatarUri: string
  rate?: Nullable<number>
  userDevices: UserDevice[],
  wantsOffersOnMail: boolean
}


export interface Image {
  // @todo Maybe duplicate
  name: string
  type: string
  uri: string
}

export interface Address {
  lat: number
  lng: number
  streetName?: string
  streetNumber?: string
  apartmentNumber?: string
  hasElevator: boolean
  floor?: string
  locality: string // City or any other place
  postalCode: string
  countryName: string
  countryCode: string
  establishment: string
}

export interface PostImage {
  id: number
  imageUri: string
  postId: string
  order: number
}

export interface Post {
  id?: number
  addressFrom: Nullable<Address>
  addressTo: Nullable<Address>
  wantsCarriers: boolean
  transportationServicesDescription: string
  dimensionsDescription: string
  postImages: PostImage[]
  distance: number
  serviceType: Nullable<Service>
  status: PostStatus
  transportDate: string
  createdAt: string
  updatedAt: string
}

export interface PostFull extends Post {
  user: User
}

export interface PostWithOffers extends Post {
  offers: Offer[]
}

export enum PostStatus {
  Offers,
  SelectedCarrier,
  OnTheWay,
  Finished,
  Canceled
}

export enum OfferStatus {
  PENDING,
  ACCEPTED,
  DECLINED
}
export interface Offer {
  id?: number
  carrierId: number
  postId: number
  price: number
  comment: string
  status: OfferStatus
}

export interface OfferWithCarrier extends Offer {
  id: number
  carrier: Carrier
  createdAt: string
  updatedAt: string
}

export interface UserRating {
  id: number
  raterId: number
  userId: number
  postId: number
  rate: number
  comment: string
  createdAt: string
  raterName: string
  raterAvatarUri: string
  raterRating: number
}
export interface UserRatingWithUser extends UserRating {
  user: {
    name: string
    avatarUri: string
  }
}
export interface UserProfile extends User {
  userRatings: UserRating[]
  carrier: Carrier // You can only see carriers in web app
  posts: Post[]
}

export type GeocoderErrorStatus = 'ZERO_RESULTS'

export interface Settings {
  maintenance: boolean
  appVersion: string
  androidStoreLink: string
}

export type GeocoderAddressComponentType =
  'route' | 'locality' | 'street_number' | 'postal_code' | 'establishment' | 'country'

export interface GeocoderAddressComponent {
  long_name: string
  short_name: string
  types: GeocoderAddressComponentType[]
}

export interface GeocoderAddress {
  address_components: GeocoderAddressComponent[]
  formattedAddress: string
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

export interface CarriersPublic {
  userId: number
  name: string
  servicesDescription: string
  userRatings: number
  avatarURL: string
}
