import { FC, useEffect, useState } from 'react'
import { signInWithEmailAndPassword, updateEmail } from 'firebase/auth'
import { isEmail } from 'utils/helpers'
import { useGetUserQuery, useUpdateUserMutation } from 'services/api/usersApi'
import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from 'initFirebase'
import { Loader } from 'components/Loader/Loader'


const FCLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const ProfileForm: FC<{ onComplete(): void }> = ({ onComplete }) => {
  const [userAuth] = useAuthState(auth)
  const {
    data: user,
    isLoading,
    refetch
  } = useGetUserQuery(undefined, { skip: !userAuth })
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [wantsOffersOnMail, setWantsOffersOnMail] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')
  const [updateUser] = useUpdateUserMutation()

  const onSave = async () => {
    if (!user || !userAuth) {
      return
    }
    if (!name) {
      setNameError('Ovo polje je obavezno')
      return
    }
    if (!isEmail(email)) {
      setEmailError('Neispravna email adresa')
      return
    }
    try {
      if (email !== userAuth.email && userAuth.email !== null) {
        const {user: newUser} = await signInWithEmailAndPassword(auth, userAuth.email, password)
        await updateEmail(newUser, email)
      }
      await refetch()
      setTimeout(async () => {
        await updateUser({
          name, email, wantsOffersOnMail
        })
        await refetch()
        onComplete()
      }, 1000)
    } catch (e) {
      console.error(e, 'email update error')
    }
  }

  useEffect(() => {
    if (user?.name) {
      setName(user.name)
    }
    if (userAuth?.email) {
      setEmail(userAuth.email)
    }
    if (user?.wantsOffersOnMail) {
      setWantsOffersOnMail(user.wantsOffersOnMail)
    }
  }, [user?.name, userAuth?.email, user?.wantsOffersOnMail])

  if (isLoading) {
    return <Grid container><Loader/></Grid>
  }
  return (
    <Grid container flexDirection={'column'} spacing={2} marginTop={2}>
      <Grid item>
      <TextField
        label={'Vaše ime'}
        value={name}
        onChange={(e) => {
          setName(e.target.value)
        }}
        error={!!nameError}
        helperText={nameError}/>
      </Grid>
      <Grid item>
        <TextField
          label={'Email adresa'}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          error={!!emailError}
          helperText={emailError}/>
      </Grid>
      {
        email !== userAuth?.email &&
        <Grid item>
          <TextField
            label={'Lozinka'}
            value={password}
            type={'password'}
            helperText={'Promena email-a zahteva unos lozinke'}
            onChange={(e) => {
              setPassword(e.target.value)
            }}/>
        </Grid>
      }
      <Grid item>
        <FCLabel
          control={
            <Stack direction={'row'} alignItems={'center'}>
              <Switch
                value={wantsOffersOnMail}
                checked={wantsOffersOnMail}
                onChange={() => {
                  setWantsOffersOnMail(!wantsOffersOnMail)
                }}/>
              <Typography>{wantsOffersOnMail ? 'Da' : 'Ne'}</Typography>
            </Stack>
          }
          labelPlacement={'start'}
          label={'Dozvoli email notifikacije'}/>
      </Grid>
      <Grid item container marginTop={2}>
        <Button color='secondary' variant={'contained'} onClick={onSave}>Izmeni</Button>
      </Grid>
    </Grid>
  )
}
