import { FC } from 'react'
import { Button, ButtonProps, styled } from '@mui/material'

const BITStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 64px;
  .MuiButton-startIcon, .MuiButton-endIcon {
    margin-inline: 0;
    min-height: 34px;
  }
  :hover {
    background-color: transparent;
  }
}
`

export const ButtonIconTop: FC<ButtonProps> = (props) => {
  return (
    <BITStyled
      disableRipple
      variant={'text'}
      color={'secondary'}
      fullWidth={false}
      {...props}/>
  )
}
